const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');

function clerkRedeem(data,callback) {
    axios({
        url: config_api.commerce+"/withdraw",
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function listRequestRedeem(maxReq,type,callback) {
    axios({
        url: `${config_api.commerce}/customer_request?max=${maxReq}&${type}`,
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function getRequestRedeem(id,callback) {
    axios({
        url: `${config_api.commerce}/customer_request/${id}`,
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function editRequestRedeem(id,data,callback) {
    axios({
        url: config_api.commerce+"/customer_request/"+id,
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports = {
    clerkRedeem: clerkRedeem,
    listRequestRedeem:listRequestRedeem,
    getRequestRedeem:getRequestRedeem,
    editRequestRedeem: editRequestRedeem

};
import React from "react";
import { Col, Row,  Modal, ModalHeader, ModalBody, ModalFooter, Button,Table, InputGroup, InputGroupAddon, Input, FormGroup, Label,Card, CardTitle, CardBody,CustomInput} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatisticV2} from '../api/apiStatistic'
import {getAllKTMN,updateKTMN,deleteKTMN} from '../api/apiKienthiet'
import apiAdmin, {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'
import { Edit, Lock, Unlock, UserPlus } from "react-feather";
import {get_urlImage} from '../../../config/config'
import {convertDate,isEmpty,range,getMoneyFormat} from "../../../utils";
const moment = require('moment')

let STATUS=['Nháp','Đang mở','Hoàn thành','Đã hủy','Đã in'];
const productDataset = [
    {
        "value": 1,
        "label": "Mega 6/45",
        "slug": "mega",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 1,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 45,
                "price": 10
            },
            {
                "id": 2,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 45,
                "price": 400
            },
            {
                "id": 3,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 45,
                "price": 70
            },
            {
                "id": 4,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 45,
                "price": 280
            },
            {
                "id": 5,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 45,
                "price": 840
            },
            {
                "id": 6,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 45,
                "price": 2100
            },
            {
                "id": 7,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 45,
                "price": 4620
            },
            {
                "id": 8,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 45,
                "price": 9240
            },
            {
                "id": 9,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 45,
                "price": 17160
            },
            {
                "id": 10,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 45,
                "price": 30030
            },
            {
                "id": 11,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 45,
                "price": 50050
            },
            {
                "id": 12,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 45,
                "price": 185564
            }
        ]
    },
    {
        "value": 2,
        "label": "Power 6/55",
        "slug": "power",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 21,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 55,
                "price": 10
            },
            {
                "id": 22,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 55,
                "price": 500
            },
            {
                "id": 23,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 55,
                "price": 70
            },
            {
                "id": 24,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 55,
                "price": 280
            },
            {
                "id": 25,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 55,
                "price": 840
            },
            {
                "id": 26,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 55,
                "price": 2100
            },
            {
                "id": 27,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 55,
                "price": 4620
            },
            {
                "id": 28,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 55,
                "price": 9240
            },
            {
                "id": 29,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 55,
                "price": 17160
            },
            {
                "id": 30,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 55,
                "price": 30030
            },
            {
                "id": 31,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 55,
                "price": 50050
            },
            {
                "id": 32,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 55,
                "price": 185564
            }
        ]
    }
];
const Product=['','Mega 6/45','Power 6/55']
class Filter extends React.Component {
    render() {
        return (
            <span className="m-2 filter-input">
                <Label className="d-block content-title-bold m-0">{this.props.label}</Label>
                {
                    this.props.type==="text" ? (
                        this.props.smallOption ? (
                            <InputGroup className="mb-3 smallOption">
                                <CustomInput type="select" name={"option_"+this.props.name} className="smallOption-statistic d-inline" onChange={this.props.action}>
                                    <option key="0" value={""}>Tất cả</option>
                                    {
                                        this.props.smallOption.map((option,key)=>{
                                            return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                        })
                                    }
                                </CustomInput>
                                <input name={this.props.name||"nullName"} type="number" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                            </InputGroup>
                        ) : (
                            <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                        )
                    ): this.props.type==="option" ?(
                        <select class="custom-select header-select-statistic" name={this.props.name||"nullName"} style={this.props.style} onChange={this.props.action}>
                            <option key="0" value={""}>Tất cả</option>
                            {
                                this.props.optionSelect.map((option,key)=>{
                                    return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                })
                            }
                        </select>
                    ): this.props.label
                }
            </span>
        );
    }
}
class ModalTicket extends React.Component {
    
    constructor(props){
        super(props);
        this.state={
            holders:[]
        }
    }

    componentDidMount(){
       
    }
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.showModalTicket} className='w1000'>
                    <ModalHeader toggle={this.props.closeModalTicket} >
                        <div className="font-weight-bold">
                        Vé: {this.props.data.kTicket}, Tỉnh: {this.props.data.productOption.name}, Ngày xổ: {this.props.data.kOpenTime}
                        </div>
                    </ModalHeader>
                    <ModalBody  >
                        <img
                            src={get_urlImage(this.props.data.kImage)}
                            style={{width:'100%'}}
                        />
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
class KienthietManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date:  new Date(),   
            dateCreate: new Date(),                   
            data:[],         
            showModalTicket:false, 
            statusId:1,
            limit:10,
            offset:0,
            isLoading:false,
            modalCreate:false,
            showModalTicket:false,
            selectedImage:null,
            statistic:[],
            countCreate:0,
            isDeadStock:false
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "Id "},
            {label: "Ngày Xổ"},
            {label: "Tỉnh"},  
            {label: "Vé"}, 
            {label: "Trạng thái"},
            {label: "Số vé"},
            {label: "Vé đã mua"},
            {label: "Ảnh vé"},  
            {label: "Hành động"},                       
        ];
        this.filter={
            size: 50,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    
    getData(){    
        if(this.state.isLoading)   
            return;
        else this.setState({isLoading:true})
        let obj={
            limit:this.state.limit,
            offset:this.state.offset,
            date:this.state.date?moment( this.state.date).format("DD-MM-YYYY"):undefined,
            status:this.state.statusIdSearch,
            isDeadStock:this.state.isDeadStock?true:undefined,
            ticketNum:this.state.ticketNum
        }
        getAllKTMN(obj,(err,data)=>{
            if(err){
                alert(err);
            }else{
                // let calculate={
                //     importCount:0,buyedCount:0
                // }
                this.setState({data:data.result,statistic:data.statistic||[],countCreate:data.countCreated||0});
            }
            this.setState({isLoading:false})
        })
    }   
    
    inputOnchange(event) {      
        console.log(event.target.value)  
                this.setState({
                    [event.target.name]: event.target.value,                    
            });                        
    }
    
    componentDidMount(){
        this.getData(null);
    }
    toggle(component1, component2, value) {    
        this.setState({[component1]:!this.state[component1]});
    }
    handCreateGroup(){
        
        let data={
            TicketStart:this.state.TicketStart,
            TicketEnd:this.state.TicketEnd,
            OpenTime:moment(this.state.dateCreate).format('DD-MM-YYYY'),
            MinBuyTicket:parseInt(this.state.MinBuyTicket),
            Status:this.state.statusCreate,
            Prize:this.state.Prize/1000,
            MaxTickets:parseInt( this.state.MaxTickets),
            CharacterTicket:this.state.CharacterTicket,
        }
        if(data.TicketEnd<data.TicketStart)
        {
            alert('Lỗi vé TicketEnd<TicketStart')
        }
        if(data.MaxTickets<data.MinBuyTicket)
        {
            alert('Lỗi vé MaxTickets<MinBuyTicket')
        }
        // createGroup(data,(err,res)=>{
        //     if(err) alert(err);
        //     else {this.setState({modalCreate:false}); this.getData()}
        // })
    }
    
    handleChangeStatus(status,id){
      
    }
    handleNext(){
        if(this.state.data.length===0)
        {
            return;
        }
            
        this.setState({offset:this.state.offset+this.state.limit},()=>{
            this.getData()
        });
    }
    handleBack(){
        if(this.state.offset===0)
        {
            return;
        }
            
        this.setState({offset:this.state.offset-this.state.limit},()=>{
            this.getData()
        });
    }
    handleUpdateStatus=(data,status)=>{
        data.kStatus = status;
        updateKTMN(data,(err,res)=>{
            if(!err) this.getData();
        });
    }
    handleDeleteTicket=(data)=>{        
        deleteKTMN(data,(err,res)=>{
            if(!err) this.getData();
        });
    }
    render() {
        return(
            <React.Fragment>
            {this.state.selectedTicket&&<ModalTicket
                data={this.state.selectedTicket}
                showModalTicket={this.state.selectedTicket!=null}
                closeModalTicket={()=>this.setState({selectedTicket:null})}
            />}
            {
                this.state.statistic.length>0&&  <Row className='m-0 mt-3'>
                <Col className='p-2 m-2' style={{borderRadius:5,backgroundColor:'red'}}>
                    <p style={{color:'#fff',fontSize:20}}>Tổng vé bán</p>
                    <p style={{color:'#fff',fontSize:20}}>Vé tạo trong ngày: {this.state.countCreate.maxTicketWithCN}</p>
                   {
                        this.state.statistic.map(e=>{
                            return <p style={{color:'#fff',fontSize:14,marginBottom:5}}>{e.productOption.name}-{e.maxTicket} vé</p>
                        })
                    }
                </Col>
                <Col className='p-2 m-2' style={{borderRadius:5,backgroundColor:'green'}}>
                    <p style={{color:'#fff',fontSize:20}}>Tổng vé tồn</p>
                    {
                        this.state.statistic.map(e=>{
                            return <p style={{color:'#fff',fontSize:14,marginBottom:5}}>{e.productOption.name}-{e.maxTicket-e.ticketBuyed} vé</p>
                        })
                    }
                </Col>
                <Col className='p-2 m-2' style={{borderRadius:5,backgroundColor:'orange'}}>
                    <p style={{color:'#fff',fontSize:20}}>Vé đã bán</p>
                   {
                        this.state.statistic.map(e=>{
                            return <p style={{color:'#fff',fontSize:14,marginBottom:5}}>{e.productOption.name}-{e.ticketBuyed} vé</p>
                        })
                    }
                </Col>
                <Col className='p-2 m-2' style={{borderRadius:5,backgroundColor:'blue'}}>

                </Col>
            </Row>
            }
          
                <Modal isOpen={this.state.modalCreate}>
                        <ModalHeader>Tạo mới nhóm</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label addonType='prepend'>Số vé bắt đầu</Label>
                                <Input name='TicketStart' onChange={this.inputOnchange.bind(this)} />
                            </FormGroup>
                            <FormGroup>
                                <Label addonType='prepend'>Số vé kết thúc</Label>
                                <Input name='TicketEnd' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup>                            
                            <FormGroup className='mb-3'>
                               <div> <Label addonType='prepend'>Ngày xổ</Label></div>
                                <DatePicker
                                    isClearable
                                    showPopperArrow={false}
                                    selected={this.state.dateCreate===null ? new Date(): this.state.dateCreate}
                                    onChange={date => this.setState({dateCreate: date})}
                                    dateFormat="dd-MM-yyyy"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label addonType='prepend'>Giá</Label>
                                <Input name='Prize' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup>  
                            <FormGroup>
                                <Label addonType='prepend'>Trạng thái</Label>
                                <Select
                                    name='productId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    defaultValue={1}
                                    options={[{value:0,label:'Không hoạt đông'},{value:1,label:'Hoạt động'}]}
                                    onChange={(value)=>{
                                       this.setState({statusCreate:value.value});                                       
                                    }}
                                />
                            </FormGroup> 
                            <FormGroup>
                                <Label addonType='prepend'>Số vé</Label>
                                <Input name='MaxTickets' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup>  
                            <FormGroup>
                                <Label addonType='prepend'>Mua vé tối thiểu</Label>
                                <Input name='MinBuyTicket' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup> 
                            <FormGroup>
                                <Label addonType='prepend'>Ký tự</Label>
                                <Input name='CharacterTicket' onChange={this.inputOnchange.bind(this)}/>
                            </FormGroup> 
                        </ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={this.toggle.bind(this, "modalCreate", null, false)}>
                                Hủy bỏ
                            </Button>
                            <Button color='success' onClick={this.handCreateGroup.bind(this)}>
                                Lưu
                            </Button>
                        </ModalFooter>
                    </Modal>
                <div className='mx-4 p-3 w-120'>
                    
                    <Row className='justify-content-center m-0 mt-3'>
                        
                        <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Trạng thái</Label>
                            <Select
                                    name='productId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    options={[{value:null,label:'Tất cả'},{value:0,label:'Nháp'},{value:1,label:'Đang mở'}]}
                                    onChange={(value)=>{
                                       this.setState({statusIdSearch:value.value});                                       
                                    }}
                                />
                        </FormGroup>
                        <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Ngày mở thưởng</Label>
                            <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.date}
                            onChange={date => {console.log(date); this.setState({date: date})}}
                            dateFormat="dd-MM-yyyy"
                        />
                        </FormGroup>
                              
                        <Filter label="Vé" type="text" name="ticketNum" action={this.inputOnchange.bind(this)}/>                      
                  
                        <div className='mt-4 p-2 d-inline'>
                            <Button color={this.state.isDeadStock?'danger':'secondary'} onClick={()=>{
                                this.setState({isDeadStock:!this.state.isDeadStock});
                            }} >
                                Lọc vé tồn {this.state.isDeadStock?'✅':'❎'}
                            </Button>
                        </div>
                       
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={()=>this.getData()} >
                                Tìm kiếm
                            </Button>                            
                        </div>
                    </Row>
                   
                </div>
               
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
               
              
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utils.isEmpty(this.state.data) ? null :
                                    this.state.data.map((row)=>{
                                        return(
                                            <tr >
                                               <td>{row.kID}</td>
                                               <td>{row.kOpenTime}</td>                                              
                                               <td>{row.productOption.name}</td>
                                               <td>{row.kTicket}</td>
                                               <td>{row.kStatus}</td>
                                               <td>{row.kMaxTicket  }</td>      
                                               <td>{row.kTicketBuyed  }</td>      
                                               <td onClick={()=>this.setState({selectedTicket:row})}>
                                                <img
                                                    width={100}
                                                    src={get_urlImage(row.kImage)  }
                                               /></td>                                    
                                               <td >
                                                {
                                                     row.kStatus==0?<span>
                                                        <Button color='success' onClick={()=>this.handleUpdateStatus(row,1)} style={{marginRight:10}}>
                                                          Xác nhận
                                                      </Button>  
                                                     <Button color='danger' onClick={()=>{this.handleDeleteTicket(row)}} >
                                                          Xoá
                                                      </Button>  
                                                      </span>:
                                                       <Button color='warning' onClick={()=>{this.handleUpdateStatus(row,0)}} style={{marginRight:10}}>
                                                       Khoá vé
                                                   </Button>  
                                                }
                                               
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <Button color='primary' onClick={()=>this.handleBack()}>{'<'}</Button>
                                    <span>...</span>
                                    <Button color='primary'  onClick={()=>this.handleNext()}>{'>'}</Button>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                   
                </div>
            </React.Fragment>
        );
    }
}

export default KienthietManager;
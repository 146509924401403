import React from "react";
import {
    Modal,ModalFooter,ModalHeader,
    Button,
    Card, CardHeader,CardBody, CardTitle, CardSubtitle, Table, Input, ModalBody
} from "reactstrap";
import utils from "../../../utils";
import Notification from "../../../components/Notification";
import {Plus, Edit, Trash2} from "react-feather";
const apiConfigReason= require("../api/apiConfigReason");

class Main extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showModal:{
                createReason:false,
                editReason:false,
                deleteReason:false,
            },
            data: null,
            type: "2",
            intendedFor: "1"
        }
    }

    toggle(component1,component2,value){
        let state = utils.getRawData(this.state);
        if(component2){
            if(value){
                state[component1][component2]  = value;
                this.setState(state);
            }
            else{
                state[component1][component2]  = !state[component1][component2];
                this.setState(state);
            }
        }
        else{
            if(value){
                state[component1]  = value;
                this.setState(state);
            }
            else{
                state[component1]  = !state[component1];
                this.setState(state);
            }
        }
    }

    onChange(event){
        this.setState({
            [event.target.name]: (event.target.value)
        });
    }

    
    createReason(){
        let data = {
            intendedFor: "1" , // ketoan
            typeReason: this.state.type,
            reason: this.state.createReason
        }
        apiConfigReason.createReason(data,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({},this.state);
                state.showModal.createReason = false;
                state.data.push(result)
                this.setState(state);
            }
        });
    }

    editReason(key){
        let data = {
            id: this.state.data[key].id,
            reason: this.state.editReason
        }
        apiConfigReason.editReason(data,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({},this.state);
                state.showModal.editReason = false;
                state.data[key] = result;
                this.setState(state);
            }
        });
    }

    deleteReason(key){
        apiConfigReason.deleteReason(this.state.data[key].id,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({},this.state);
                state.showModal.deleteReason = false;
                state.data.splice(key, 1);
                this.setState(state);
            }
        });
    }

    componentDidMount(){
        apiConfigReason.listReason(this.state.type,this.state.intendedFor,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({
                    data : result
                });
            }
        });
    }
    
    render() {
        return(
            <React.Fragment>
                            <Modal isOpen={this.state.showModal.createReason}>
                                <ModalHeader>Thêm lý do hủy yêu cầu rút tiền qua ngân hàng</ModalHeader>
                                <ModalBody>
                                    <Input name="createReason" type="textarea" onChange={this.onChange.bind(this)} rows="5"/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggle.bind(this,"showModal","createReason",null)}>
                                        Hủy bỏ
                                    </Button>
                                    <Button color="success" onClick={this.createReason.bind(this)}>
                                        Lưu
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.showModal.editReason}>
                                <ModalHeader>Chỉnh sửa lý do hủy yêu cầu rút tiền qua ngân hàng</ModalHeader>
                                <ModalBody>
                                    <Input name="editReason" type="textarea" onChange={this.onChange.bind(this)} rows="5" value={this.state.editReason}/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggle.bind(this,"showModal","editReason",null)}>
                                        Hủy bỏ
                                    </Button>
                                    <Button color="success" onClick={this.editReason.bind(this,this.state.indexRow)}>
                                        Lưu
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.showModal.deleteReason}>
                                <ModalHeader>Xác nhận xóa lý do hủy yêu cầu rút tiền qua ngân hàng</ModalHeader>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggle.bind(this,"showModal","deleteReason",null)}>
                                        Hủy bỏ
                                    </Button>
                                    <Button color="success" onClick={this.deleteReason.bind(this,this.state.indexRow)}>
                                        Lưu
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <center><Card className="border w-75 m-3 ">
                                <CardHeader style={{backgroundColor: "#e9ecef"}}>
                                    <CardTitle className="d-inline">
                                        Lý do hủy rút tiền qua ngân hàng
                                    </CardTitle>
                                    <CardSubtitle className="float-right d-inline text-success">
                                        <Plus onClick = {this.toggle.bind(this,"showModal","createReason",null)}/>
                                    </CardSubtitle>
                                </CardHeader>
                                <CardBody>
                                <Table>
                                        {
                                            this.state.data !== null && this.state.data.length!== 0
                                            ?(
                                                this.state.data.map( (row,index) =>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{row.reason}</td>
                                                            <td width="80px"> 
                                                                <Edit className="d-inline mr-2" 
                                                                    onClick={()=>{
                                                                        let state = Object.assign({"indexRow": index},this.state);
                                                                        state.showModal.editReason = true;
                                                                        state.editReason = row.reason;
                                                                        this.setState(state);
                                                                    }}
                                                                /> 
                                                                <Trash2 className="d-inline"
                                                                    onClick={()=>{
                                                                        let state = Object.assign({"indexRow": index},this.state);
                                                                        state.showModal.deleteReason = true;
                                                                        this.setState(state);
                                                                    }}
                                                                /> 
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ): (
                                                "DANH SÁCH TRỐNG"
                                            )
                                        }
                                    </Table>
                                </CardBody>
                            </Card></center>   
            </React.Fragment>
        );
    }
}



export default Main;
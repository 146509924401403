const config_api = require("../../config/config").config_api;
const utils = require("../../utils");
const axios = require('axios');

function getConfig(callback) {
    axios({
        url: config_api.admin+"/config",
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports = {
    getConfig:getConfig,
};
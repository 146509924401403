import React from "react";
import {
    Modal,ModalFooter,ModalHeader,
    Button,
    Card, CardHeader,CardBody, CardTitle, CardSubtitle,  Input, ModalBody
} from "reactstrap";
import {getRawData,isEmpty} from "../../../utils";
import Notification from "../../../components/Notification";
import {Plus, Edit, Lock } from "react-feather";
const apiPos= require("../api/apiPos");

class Pos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data : this.props.data,
            showModal:{
                editPos : false,
                lockPos : false
            }
        }
    }

    toggle(component1,component2,value){
        let state = getRawData(this.state);
        if(component2){
            if(value){
                state[component1][component2]  = value;
                this.setState(state);
            }
            else{
                state[component1][component2]  = !state[component1][component2];
                this.setState(state);
            }
        }
        else{
            if(value){
                state[component1]  = value;
                this.setState(state);
            }
            else{
                state[component1]  = !state[component1];
                this.setState(state);
            }
        }
    }


    onChange(event){
        this.setState({
            [event.target.name]: (event.target.value)
        });
    }

    editPos(){
        if(!this.state.editCode) return Notification("error","Mã POS không được để trống")
        let data = {
            code: this.state.editCode
        }
        apiPos.editPos(this.state.data.id,data,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                Notification("success", "Chỉnh sửa mã POS thành công");
                let state = Object.assign({},this.state);
                state.showModal.editPos = false;
                state.data = result;
                state.editCode = null;
                this.setState(state);
            }
        });
    }

    lockPos(){
        let data = {
            isActive: !this.state.data.isActive
        }
        apiPos.editPos(this.state.data.id,data,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                if(result.isActive){
                    Notification("success", "Đã mở khóa POS");
                } else{
                    Notification("success", "Đã khóa POS");
                }
                let state = Object.assign({},this.state);
                state.showModal.lockPos = false;
                state.data = result;
                this.setState(state);
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                        <Modal isOpen={this.state.showModal.editPos}>
                            <ModalHeader>Chỉnh sửa mã POS</ModalHeader>
                            <ModalBody>
                                <Input 
                                    name="editCode"
                                    onChange={this.onChange.bind(this)} 
                                    defaultValue={this.state.data.code}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick = {this.toggle.bind(this,"showModal","editPos",null)}>
                                    Hủy bỏ
                                </Button>
                                <Button color="success" onClick={this.editPos.bind(this)}>
                                    Lưu
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <Modal isOpen={this.state.showModal.lockPos}>
                            <ModalHeader>{`Xác nhận ${this.state.data.isActive ? "Khóa" : "mở khóa"} POS ${this.state.data.code}`}</ModalHeader>
                            <ModalFooter>
                                <Button color="secondary" onClick = {this.toggle.bind(this,"showModal","lockPos",null)}>
                                    Hủy bỏ
                                </Button>
                                <Button color="success" onClick={this.lockPos.bind(this)}>
                                    Lưu
                                </Button>
                            </ModalFooter>
                        </Modal>


                <Card className="posManagerment-pos_Block border m-2" hidden={this.props.hiddenPosInactive && !this.state.data.isActive}>
                    <CardHeader className="p-1 bg-gray">
                        <CardTitle className=" d-inline">{this.state.data.code}</CardTitle>
                        <CardSubtitle className="float-right d-inline">
                            <Edit className="cursor-pointer" onClick = {this.toggle.bind(this,"showModal","editPos",null)}/>
                            <Lock className={`${this.state.data.isActive?"text-success":"text-danger"} cursor-pointer`} onClick = {this.toggle.bind(this,"showModal","lockPos",null)}/>
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody className={`${this.state.data.isActive?null:"bg-dark"}`}>
                        {
                            isEmpty(this.state.data.staffs) ? (
                                <p>Chưa có nhân viên</p>
                            ):(
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Tài khoản</th>
                                            <th>Họ tên</th>
                                            <th>Quyền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.staffs.map((staff,key)=>{
                                            return(
                                                <tr key = {key}>
                                                    <td>{staff.username}</td>
                                                    <td>{staff.fullName}</td>
                                                    <td>{staff.role}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            )
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

class Main extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showModal:{
                createPos:false,
            },
            hiddenPosInactive:true,
            data: [],
        }
    }


    toggle(component1,component2,value){
        let state = getRawData(this.state);
        if(component2){
            if(value){
                state[component1][component2]  = value;
                this.setState(state);
            }
            else{
                state[component1][component2]  = !state[component1][component2];
                this.setState(state);
            }
        }
        else{
            if(value){
                state[component1]  = value;
                this.setState(state);
            }
            else{
                state[component1]  = !state[component1];
                this.setState(state);
            }
        }
    }

    onChange(event){
        this.setState({
            [event.target.name]: (event.target.value)
        });
    }

    createPos(){
        let data = {
            code: this.state.code
        }
        apiPos.createPos(data,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({},this.state);
                state.showModal.createPos = false;
                state.data.push(result)
                this.setState(state);
            }
        });
    }

    componentDidMount(){
        apiPos.listPos({},(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({
                    data : result
                });
            }
        });
    }

    render() {
        return(
            <React.Fragment>
                            <Modal isOpen={this.state.showModal.createPos}>
                                <ModalHeader>Thêm mã POS</ModalHeader>
                                <ModalBody>
                                    <Input name="code" onChange={this.onChange.bind(this)} maxLength={12} placeholder="Nhập mã pos 12 chữ số"/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggle.bind(this,"showModal","createPos",null)}>
                                        Hủy bỏ
                                    </Button>
                                    <Button color="success" onClick={this.createPos.bind(this)}>
                                        Lưu
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <center><Card className="border group-list-config">
                                <CardHeader style={{backgroundColor: "#e9ecef"}}>
                                    <CardTitle className="d-inline align-center">
                                        Trang quản lý mã POS
                                    </CardTitle>
                                    <CardSubtitle className="float-right d-inline text-success">
                                        <Plus className="cursor-pointer"  onClick = {this.toggle.bind(this,"showModal","createPos",null)}/>
                                    </CardSubtitle>
                                </CardHeader>
                                <CardBody>
                                    {this.state.data.map((pos,key)=>{
                                        // if( pos.isActive || !this.state.hiddenPosInactive){
                                            return(
                                                <Pos
                                                    key = {key}
                                                    data = {pos}
                                                    hiddenPosInactive = {this.state.hiddenPosInactive}
                                                />
                                            )    
                                        // }                                         
                                    })}
                                    <div className="w-100 mt-3">
                                        <Button onClick={this.toggle.bind(this,"hiddenPosInactive",null,null)}>{`${this.state.hiddenPosInactive?"Hiển thị":"Ẩn"} POS không hoạt động`}</Button>
                                    </div>
                                </CardBody>
                            </Card></center>
            </React.Fragment>
        );
    }
}



export default Main;
const moment = require("moment");

require("./NumberPrototype");

String.prototype.toUpperFisrtChacracter = function(){
    return this.charAt(0).toUpperCase() + this.substring(1);
};

String.prototype.getMoneyFormat = function () {
    let fl = parseFloat(this);
    let ff = Math.floor(fl);

    let fb = ff === fl ? "000" : (fl - ff).toFixed(3).split(".")[1];
    ff = ff.getMoneyFormat();
    return ff + "," + fb;
};

String.prototype.getDateFormat = function () {
    return moment(new Date(this)).format("DD/MM/YYYY")
};

import React from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col} from "reactstrap";
import LoadingSprinner from "../components/LoadingSprinner"
import Webcam from "react-webcam";

import utils from "../utils";
import Notification from "./Notification";
const attachment = require("../pages/apiGeneral/attachment");
const apiAttachTicket = require("../pages/Clerk/api/apiTicket").apiAttachTicket;

const ModalCamera = props => {
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        props.handleShooting(imageSrc);
        props.toggle();
    }, [props]);

    return (
        <Modal isOpen={props.isOpen} centered className='modal-camera' size='lg'>
            <ModalHeader toggle={props.toggle}>{"Chụp ảnh - " + props.label}</ModalHeader>
            <ModalBody className='text-center'>
                <Webcam
                    width={640}
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    onClick={capture}
                    videoConstraints={{width: 640,height: 480}}
                    onUserMediaError={() => {
                        document.getElementById("inputWhenCameraError_" + props.id).click();
                        props.toggle();
                    }}
                />
            </ModalBody>
            <ModalFooter className='text-center'>
                <Button onClick={capture}>Chụp ảnh</Button>
            </ModalFooter>
        </Modal>
    );
};

const ModalImage = props => {
    return (
        <React.Fragment>
            <Modal isOpen={props.isOpen} centered className='modal-camera' size='lg'>
                <ModalHeader toggle={props.toggle}>{props.label}</ModalHeader>
                <ModalBody className='text-center'>
                    <img src={props.src} height={480} style={{ maxWidth: "100%" }} />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgSrc: null,
            imgLink: null,
            show_image: false,
            show_camera: false,
            loading: false
        };
    }

    toggle(component1, component2, value) {
        let state = Object.assign({}, this.state);
        if (component2) {
            if (value) {
                state[component1][component2] = value;
                this.setState(state);
            } else {
                state[component1][component2] = !state[component1][component2];
                this.setState(state);
            }
        } else {
            if (value) {
                state[component1] = value;
                this.setState(state);
            } else {
                state[component1] = !state[component1];
                this.setState(state);
            }
        }
    }

    handleShooting(imgSrc) {
        if(this.props.folder === "ticket"){
            apiAttachTicket(this.props.oderId,this.props.ticketId, this.props.keyImg === "front" ?"imageFront":"imageBack", utils.dataURLtoFile(imgSrc), (err, result) => {
                if (err) {
                    if(err.data===undefined) return Notification("error",err)
                    switch (err.data._error_message) {
                        case "Permission denied: ":
                            Notification("error","Không có quyền");
                            break;
                        case "Ticket not found":
                            Notification("error","Vé không còn tồn tại");
                            break;
                        case "Images is null":
                            Notification("error","Ảnh không được để trống");
                            break;
                        case "Too many Term Keno: ":
                            Notification("error","Quá nhiều kỳ");
                            break;
                        case "Nothing change for this Ticket":
                            Notification("error","Yêu cầu cập nhật thông tin vé");
                            break;
                        case "Product not found!":
                            Notification("error","Kiểu vé không hợp lệ");
                            break;
                        case "ProductOption not found!":
                            Notification("error","Kiểu chơi không hợp lệ");
                            break;
                        default:
                            Notification("error", "Có lỗi xảy ra ", "Đường truyền lỗi");
                            break;
                    }
                } else {
                    try {
                        this.props.actionWhenReceiveData(this.props.keyImg, imgSrc, result.pathUrl);
                    } catch (error) {}
                }
            })
        } else{
            attachment.apiAttachment(utils.dataURLtoFile(imgSrc), this.props.folder ? this.props.folder : "ticket", (err, result) => {
                if (err) {
                    if(err.data===undefined) return Notification("error",err)
                    switch (err.data._error_message) {
                        case "Permission denied: ":
                            Notification("error","Không có quyền");
                            break;
                        case "Ticket not found":
                            Notification("error","Vé không còn tồn tại");
                            break;
                        case "Images is null":
                            Notification("error","Ảnh không được để trống");
                            break;
                        case "Too many Term Keno: ":
                            Notification("error","Quá nhiều kỳ");
                            break;
                        case "Nothing change for this Ticket":
                            Notification("error","Yêu cầu cập nhật thông tin vé");
                            break;
                        case "Product not found!":
                            Notification("error","Kiểu vé không hợp lệ");
                            break;
                        case "ProductOption not found!":
                            Notification("error","Kiểu chơi không hợp lệ");
                            break;
                        default:
                            Notification("error", "Có lỗi xảy ra ", "Đường truyền lỗi");
                            break;
                    }
                } else {
                    try {
                        this.props.actionWhenReceiveData(this.props.keyImg, imgSrc, result.pathUrl);
                    } catch (error) {}
                }
            });
        }        
    }

    handleClickImage() {
        if (utils.isEmpty(this.props.imgSrc)) {
            if(!this.props.disabled)
                this.toggle("show_camera", null, null);
        } else {
            this.toggle("show_image", null, null);
        }
    }

    handleRemovePhoto() {
        try {
            this.props.actionWhenDeleteImage(this.props.keyImg);
        } catch (error) {}
        this.setState({ imgSrc: null, imgLink: null });
    }

    onImageFileChange(event) {
        if (event.target.files) {
            this.toggle("loading",undefined,true);
            let state = Object.assign({}, this.state);
            let reader = new FileReader();
            reader.onload = e => {
                state.imgSrc = e.target.result;
            };
            if(this.props.folder === "ticket"){
                apiAttachTicket(this.props.oderId,this.props.ticketId, this.props.keyImg === "front" ?"imageFront":"imageBack", event.target.files[0], (err, result) => {
                    if (err) {
                        if (typeof(err)==="string") {
                            return Notification("error",err);
                        }
                        switch (err.data._error_message) {
                            case "Incomplete arguments 'attached_file'":
                                Notification("error","Yêu cầu thêm tệp đính kèm");
                                break;
                            case "Incomplete arguments 'contentType'":
                                Notification("error","Lỗi kiểu dữ liệu");
                                break;
                            case "Extension not allow":
                                Notification("error","Sai định dạng");
                                break;
                            case "Permission denied: ":
                                Notification("error","Không có quyền");
                                break;
                            case "Ticket not found":
                                Notification("error","Vé không còn tồn tại");
                                break;
                            case "Images is null":
                                Notification("error","Ảnh không được để trống");
                                break;
                            case "Too many Term Keno: ":
                                Notification("error","Quá nhiều kỳ");
                                break;
                            case "Nothing change for this Ticket":
                                Notification("error","Yêu cầu cập nhật thông tin vé");
                                break;
                            case "Product not found!":
                                Notification("error","Kiểu vé không hợp lệ");
                                break;
                            case "ProductOption not found!":
                                Notification("error","Kiểu chơi không hợp lệ");
                                break;
                            default:
                                Notification("error", "Có lỗi xảy ra ", "Dung lượng ảnh lớn hơn 5MB hoặc đường truyền lỗi");
                                break;
                        }
                    } else {
                        try {
                            this.props.actionWhenReceiveData(this.props.keyImg, state.imgSrc, result.pathUrl);
                        } catch (error) {}
                        state.imgLink = result.pathUrl;
                        state.loading = false;
                        this.setState(state);
                    }
                })
            }else{
                attachment.apiAttachment(event.target.files[0], this.props.folder ? this.props.folder : "ticket", (err, result) => {
                    if (err) {
                        switch (err.data._error_message) {
                            case "Incomplete arguments 'attached_file'":
                                Notification("error","Yêu cầu thêm tệp đính kèm");
                                break;
                            case "Incomplete arguments 'contentType'":
                                Notification("error","Lỗi kiểu dữ liệu");
                                break;
                            case "Extension not allow":
                                Notification("error","Sai định dạng");
                                break;
                            case "Permission denied: ":
                                Notification("error","Không có quyền");
                                break;
                            case "Ticket not found":
                                Notification("error","Vé không còn tồn tại");
                                break;
                            case "Images is null":
                                Notification("error","Ảnh không được để trống");
                                break;
                            case "Too many Term Keno: ":
                                Notification("error","Quá nhiều kỳ");
                                break;
                            case "Nothing change for this Ticket":
                                Notification("error","Yêu cầu cập nhật thông tin vé");
                                break;
                            case "Product not found!":
                                Notification("error","Kiểu vé không hợp lệ");
                                break;
                            case "ProductOption not found!":
                                Notification("error","Kiểu chơi không hợp lệ");
                                break;
                            default:
                                Notification("error", "Có lỗi xảy ra ", "Dung lượng ảnh lớn hơn 5MB hoặc đường truyền lỗi");
                                break;
                        }
                    } else {
                        try {
                            this.props.actionWhenReceiveData(this.props.keyImg, state.imgSrc, result.pathUrl);
                        } catch (error) {}
                        state.imgLink = result.pathUrl;
                        state.loading = false;
                        this.setState(state);
                    }
                });
            }
            
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="position-relative">
                        <ModalCamera
                            isOpen={this.state.show_camera}
                            label={this.props.label}
                            id={this.props.id}
                            toggle={this.toggle.bind(this, "show_camera", null, null)}
                            handleShooting={this.handleShooting.bind(this)}
                        />
                        <ModalImage 
                            id={this.props.id} 
                            label={this.props.label} 
                            isOpen={this.state.show_image} 
                            src={this.props.imgSrc} 
                            toggle={this.toggle.bind(this, "show_image", null, null)} 
                        />
                        <div className='text-center'>
                            <div className='content-title-bold'>{this.props.label}</div>
                            <input  
                                type='file'
                                accept='image/png,image/jpeg'
                                id={"inputWhenCameraError_" + this.props.id}
                                className='d-none'
                                onChange={this.onImageFileChange.bind(this)}
                                onClick={event => {
                                    event.target.value = null;
                                }}
                            />
                        </div>
                        <LoadingSprinner className={`position-absolute w-50 loading-modal-camera ${this.state.loading ? "d-block":"d-none"}`}/>
                        <img src={this.props.imgSrc} className='ticket-photo' onClick={this.handleClickImage.bind(this)} alt='' style={{maxWidth:"100%"}}/>
                    </Col>
                </Row>
                <Col>
                    <div className='text-center'>
                        <Button 
                            className="m-1 mt-2 hidden-md-down"
                            disabled={this.props.disabled || this.props.disabledShooting} 
                            onClick={this.toggle.bind(this, "show_camera", null, null)}
                        >
                            Chụp ảnh
                        </Button>
                        <Button className={'m-1 mt-2'}
                                disabled={this.props.disabled || this.props.disabledShooting}
                                onClick={utils.clickElement.bind(this,"inputWhenCameraError_"+this.props.id)}
                        >
                            Chọn tệp
                        </Button>
                        <Button className='m-1 mt-2' disabled={this.props.disabled} onClick={this.handleRemovePhoto.bind(this)}>
                            &nbsp;Xóa ảnh&nbsp;
                        </Button>
                    </div>
                </Col>
            </React.Fragment>
        );
    }
}

//     EXAMPLE
//             <Main
//                 keyImg= 1
//                 label="lê đăng cương"
//                 id="ledangcuong"
//                 imgSrc=this.state.imgsrc
//                 folder = "ticket" // || "avatar" || "wallet" deefault= ticket
//                 actionWhenReceiveData={this.log}
//                 actionWhenDeleteImage={this.del}
//             />

export default Main;

import React from "react";

import {
    Col,Row,
    Button,
    InputGroup,InputGroupAddon,Input,
    FormGroup,
    Label,
} from "reactstrap";

import {Search,} from "react-feather";
import ModalCamera from "../../components/ModalCamera";
import Notification from "../../components/Notification";
import ButtonTimeout from "../../components/ButtonTimeout";
import utils from "../../utils";

const apiRecharge = require("./api/apiRecharge");
const getCustommerFromPhone = require("../apiGeneral/getCustommerFromPhone");
const apiConfig = require("../apiGeneral/apiConfig");


class ReCharge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            money: 0,
            phone_number: "",

            dataCustommer:null,

            imgSrc: null,
            imgLink: null,

            amountMax : null
        }
    }

    handleChange(event) {
        if (event.target.name === "money") {
            let amount = event.target.value.replace(/[^\d]/g, "");
            if (utils.isEmpty(amount))
                amount = 0;
            try {
                amount = parseInt(amount);
                if (Number.isNaN(amount))
                    return;
                this.setState({money: amount});
                return;
            } catch (e) {
                return;
            }
        }
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSearch() {
        if(!utils.isEmpty(this.state.phone_number)){
            getCustommerFromPhone.getData(this.state.phone_number,(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại định dạng các trường đầu vào");
                } else {
                    if(utils.isEmpty(result)){
                        Notification("warning","Không tồn tại số điện thoại trong hệ thống");
                    }
                    let state = Object.assign({}, this.state);
                    state.dataCustommer = result;
                    state.money= 0;
                    state.imgSrc= null;
                    state.imgLink= null;
                    this.setState(state);
                }
            });
        }
    }

    handleReCharge() {
        utils.confirm(`Xác nhận nạp <span class="money-title">${this.state.money.getMoneyFormat()} đồng</span> vào tài khoản <span class="content-title-bold">${this.state.dataCustommer.phoneNumber}</span>`, (check) => {
            if(check){
                apiRecharge.clerkDeposit({phoneNumber:this.state.phone_number,amount:(this.state.money/1000).toString(),proof:this.state.imgLink,note:""},(err,result)=>  {
                    if(err){
                        switch (err.data._error_message) {
                            case "Amount must be a positive number":
                                Notification("error", "Lỗi", "Số tiền phải là một số dương")
                                break;
                            case "Amount too much":
                                Notification("error", "Lỗi", "Số tiền quá giới hạn cho phép trong một lần nạp")
                                break;
                            case "Out of money per day":
                                Notification("error", "Lỗi", "Nhân viên nạp quá giới hạn cho phép trong một ngày")
                                break;
                            case "Phone number doesn't exist or not activated":
                                Notification("error", "Lỗi", "Số điện thoại không tồn tại hoặc chưa kích hoạt")
                                break;
                            default:
                                Notification("error", "Lỗi", "Vui lòng thử lại sau!")
                                break;
                        }
                    } else {
                        Notification("success" ,"Nạp tiền thành công.");
                        let state = Object.assign({}, this.state);
                        state.money=0;
                        state.phone_number="";
                        state.imgSrc=null;
                        state.dataCustommer=null;
                        this.setState(state);
                    }
                });
            }
        })
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    handleShooting(id,imgSrc,imgLink) {
        this.setState({imgSrc:imgSrc,imgLink: imgLink});
    }

    handleRemovePhoto(id) {
        this.setState({imgSrc: null,imgLink: null});
    }

    checkEmptyField(){
        if(utils.isEmpty(this.state.dataCustommer)){
            Notification("error","Chưa đủ thông tin","Chưa có thông tin khách hàng");
        }
        else if(utils.isEmpty(this.state.money) || this.state.money===0){
            Notification("error","Chưa đủ thông tin","Số tiền muốn nạp không được để trống");
        }
        else if(this.state.money%1000 !== 0){
            Notification("error","Số tiền không hợp lệ","Số phải chia hết cho 1000 đồng");
        }
        else if(utils.isEmpty(this.state.imgSrc) || utils.isEmpty(this.state.dataCustommer)){
            Notification("error","Chưa đủ thông tin","Chưa chụp hóa đơn");
        }
        else{
            this.handleReCharge();
        }
    }

    componentDidMount(){
        apiConfig.getConfig((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra kết nối hoặc báo cho quản trị viên");
            } else {
                this.setState({amountMax: result});
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Col className=''>
                    <Row style={{ color: "blue", fontWeight: 600 }}>
                        <Search /> TÌM KIẾM THÔNG TIN KHÁCH HÀNG NẠP TIỀN
                    </Row>
                    <Row className='mt-2 search-row mx-0'>
                        <InputGroup>
                            <InputGroupAddon addonType={"append"}>Số ĐT</InputGroupAddon>
                            <Input
                                type='text'
                                name='phone_number'
                                maxLength='10'
                                onChange={this.handleChange.bind(this)}
                                value={this.state.phone_number}
                                onKeyUp={(event) => {
                                    if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                    if (event.key === "Enter") {
                                        this.handleSearch();
                                    }
                                }}
                            />
                            <InputGroupAddon addonType={"prepend"}>
                                <Button onClick={this.handleSearch.bind(this)}>Tìm kiếm</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <Row>
                                <Col sm="12" md="6" className='text-center p-2 border'>
                                    <div className='content-title-bold'>Thông tin chi tiết khách hàng</div>
                                    <FormGroup row className='mt-2 mx-0'>
                                        <Label for='name' sm={3}>
                                            Họ và tên:
                                        </Label>
                                        <Col sm={8}>
                                            <Input name='name' id='name' placeholder={this.state.dataCustommer ? this.state.dataCustommer.fullName : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-2 mx-0'>
                                        <Label for='phoneNumber' sm={3}>
                                            Số điện thoại:
                                        </Label>
                                        <Col sm={8}>
                                            <Input name='phoneNumber' id='phoneNumber' placeholder={this.state.dataCustommer ? this.state.dataCustommer.phoneNumber : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-2 mx-0'>
                                        <Label for='CMTND' sm={3}>
                                            Giới tính:
                                        </Label>
                                        <Col sm={8}>
                                            <Input name='CMTND' id='CMTND' placeholder={this.state.dataCustommer ? (this.state.dataCustommer.gender === "M" ? "Nam" : "Nữ") : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-2 mx-0'>
                                        <Label for='idCardNumber' sm={3}>
                                            Số CMND:
                                        </Label>
                                        <Col sm={8}>
                                            <Input type='idCardNumber' name='idCardNumber' id='idCardNumber' placeholder={this.state.dataCustommer ? this.state.dataCustommer.idCardNumber : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-2 mx-0'>
                                        <Label for='createDate' sm={3}>
                                            Ngày đăng kí:
                                        </Label>
                                        <Col sm={8}>
                                            <Input type='createDate' name='createDate' id='createDate' placeholder={this.state.dataCustommer ? this.state.dataCustommer.createDate : "Trống"} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-2 mx-0'>
                                        <Label  sm={3}>
                                            Số dư tài khoản:
                                        </Label>
                                        <Col sm={8} className='content-title-bold'>
                                            {this.state.dataCustommer ? utils.convertMoneyFomat(this.state.dataCustommer.wallet.balance) : "0"}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className='mt-2 mx-0'>
                                        <Label  sm={3}>
                                            Số dư khả dụng:
                                        </Label>
                                        <Col sm={8} className='content-title-bold'>
                                            {this.state.dataCustommer ? utils.convertMoneyFomat(this.state.dataCustommer.wallet.availableBalance) : "0"}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6" className='text-center pt-2 border'>
                                    <ModalCamera
                                        label='Ảnh hóa đơn'
                                        id='imageBillRechange'
                                        imgSrc={this.state.imgSrc}
                                        folder='wallet'
                                        actionWhenReceiveData={this.handleShooting.bind(this)}
                                        actionWhenDeleteImage={this.handleRemovePhoto.bind(this)}
                                    />
                                    <span className='mb-1 content-title-bold'>Nhập số tiền cần nạp</span>
                                    <InputGroup size='lg' className='mb-3'>
                                        <InputGroupAddon addonType={"append"}>Số tiền</InputGroupAddon>
                                        <Input
                                            type='text'
                                            name='money'
                                            onChange={this.handleChange.bind(this)}
                                            value={this.state.money.getMoneyFormat()}
                                            maxLength='15'
                                        />
                                        <InputGroupAddon addonType={"prepend"}>
                                            <ButtonTimeout 
                                                color={utils.isEmpty(this.state.dataCustommer) || this.state.money===0 ||utils.isEmpty(this.state.imgSrc) ? 'dark' : 'success' }
                                                onClick={this.checkEmptyField.bind(this)}
                                            >
                                                Nạp tiền
                                            </ButtonTimeout>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </React.Fragment>
        );
    }
}


export default ReCharge;
import React from "react";
import utils from "../../../utils";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import { Edit, Lock, Unlock } from "react-feather";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "../../../components/Notification";
const { getNameDistrictFromCode } = require("../../../config/listDistrict");
const { getNameCityFromCode } = require("../../../config/listCity");
const { arrayCity } = require("../../../config/city");
const apiNotifi = require("./../api/apiNotifi");
const apiLocation = require("./../api/apiLocation");
export class notifiManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          id: 0,
          title: "đang tải",
          content: "đang tải",
          createdDate: "đang tải",
        },
      ],
      showModal: false,

      title: "",
      content: "",
    };
  }
  componentDidMount() {
    apiNotifi.listNotifiCommon(1, (d) => {
      console.log(d);
      this.setState({ ...this.state, data: d });
    });
  }
  toggle = () => {
    this.setState({ ...this.state, showModal: !this.state.showModal });
  };
  onInputChange = (event) => {
    console.log(event);
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  submitNotifi = () => {
    if (this.state.title == "" || this.state.content == "") {
      return;
    }
    apiNotifi.createNotifiCommon({
      title: this.state.title,
      content: this.state.content,
    },()=>{
      let data=this.state.data;
      data.push({id:0,title:this.state.title,content:this.state.content})
      this.setState({...this.state,data:data,showModal:false});

    });
  };
  render() {
    const tableColumns = [
      {
        dataField: "id",
        text: "Id",
      },
      {
        dataField: "title",
        text: "Tiêu đề",
      },
      {
        dataField: "content",
        text: "Nội dung",
      },
      {
        dataField: "createdDate",
        text: "Tạo ngày",
      },
      {
        headerFormatter: (column, colIndex) => {
          return (
            <Button
              className="float-center m-2 btn-suscess text-white"
              color="success"
              onClick={() => this.toggle()}
            >
              Tạo thông báo
              <FontAwesomeIcon className="ml-2" icon={faStore} />
            </Button>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Modal isOpen={this.state.showModal}>
          <ModalHeader>Tạo thông báo</ModalHeader>
          <ModalBody>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Tiêu đề</InputGroupAddon>
              <Input  name="title" onChange={(e) => this.onInputChange(e)} />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Nội dung</InputGroupAddon>
              <Input name="content" type='textarea' onChange={(e) => this.onInputChange(e)} />
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              Hủy bỏ
            </Button>
            <Button color="success" onClick={()=>this.submitNotifi()}>Lưu</Button>
          </ModalFooter>
        </Modal>
        <div>
          <div className="overflow-x-auto table-overflow-x">
            <BootstrapTable
              bootstrap4
              bordered={true}
              keyField="id"
              data={this.state.data}
              columns={tableColumns}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default notifiManagement;

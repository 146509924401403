import React from "react";
import {
    Modal,ModalFooter,ModalHeader,
    Input,Button,

    InputGroup,InputGroupAddon, 
} from "reactstrap";
import utils from "../../../utils";
import Notification from "../../../components/Notification";
import InputMask from "react-input-mask";
const apiAdmin= require("../api/apiAdmin");

class SEO extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data : {},
            showModal:{
                updateConfig:false
            }
        }
        
    }    
    componentDidMount(){
        apiAdmin.getSeo((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {  
                this.data=result;              
                this.setState({
                   data:result
                });
            }
        });
    }
    inputOnchange(event){
        let data=this.state.data;
        data[event.target.name] = event.target.value;       
        this.setState({
                   data
        });
    }
    saveSeo=(name)=>{
            apiAdmin.editSeo(name,JSON.parse(this.state.data[name]),()=>{
                Notification("success","Cài đặt thành công","Cập nhật đã được thay đổi")
            })
    }
    render() {
        return(
            <React.Fragment>
                           
                <center>
                    <div className={ utils.detectDevice(["fullhd","hd"],"or")? "text-center w-50 group-list-config" : "text-center w-100 group-list-config"} >
                    {
                        Object.keys(this.state.data).map((file)=>(
                            <InputGroup className="mb-3" bsSize = "lg">
                                <InputGroupAddon  addonType="prepend" style={{fontSize:"30"}}>
                                <span className="input-group-text">{file}</span>
                                </InputGroupAddon>
                                <Input 
                                    bsSize = "lg"                                   
                                    type='textarea'
                                    name={file}
                                    style={{height:300}}
                                    value={this.state.data[file]}                                    
                                    multiple={true}  
                                    onChange={this.inputOnchange.bind(this)}                                                                      
                                />        
                              <InputGroupAddon classname="input-group-prepend-config-backlabel" style={{cursor:'pointer'}} onClick={()=> this.saveSeo(file)} addonType="prepend" bsSize = "lg"> Cập nhật </InputGroupAddon>                      
                            </InputGroup>
                        ))
                    }
                        
                        

                        <center>
                           
                        </center>
                    </div>
                </center>  
            </React.Fragment>
        );
    }
}



export default SEO;
import React from "react";
import { Row, Label, FormGroup, Button,Table, InputGroup,CustomInput} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatistic} from '../api/apiStatistic'
import {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'

const moment = require('moment')
class Filter extends React.Component {
    render() {
        return (
            <span className="m-2 filter-input">
                <Label className="d-block content-title-bold m-0">{this.props.label}</Label>
                {
                    this.props.type==="text" ? (
                        this.props.smallOption ? (
                            <InputGroup className="mb-3 smallOption">
                                <CustomInput type="select" name={"option_"+this.props.name} className="smallOption-statistic d-inline" onChange={this.props.action}>
                                    <option key="0" value={""}>Tất cả</option>
                                    {
                                        this.props.smallOption.map((option,key)=>{
                                            return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                        })
                                    }
                                </CustomInput>
                                <input name={this.props.name||"nullName"} type="number" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                            </InputGroup>
                        ) : (
                            <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                        )
                    ): this.props.type==="option" ?(
                        <select class="custom-select header-select-statistic" name={this.props.name||"nullName"} style={this.props.style} onChange={this.props.action}>
                            <option key="0" value={""}>Tất cả</option>
                            {
                                this.props.optionSelect.map((option,key)=>{
                                    return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                })
                            }
                        </select>
                    ): this.props.label
                }
            </span>
        );
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromCreated: new Date(),
            toCreated: new Date(),
            statisticData : [],
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "STT"},
            {label: "Họ tên"},
            {label: "SĐT"},
            {label: "Số tiền dư trong tài khoản"},
            {label: "Tổng tiền nạp vào"},
            {label: "Tổng tiền khuyến mại"},
            {label: "Tổng tiền trúng thưởng"},
            {label: "Tổng tiền rút"},
            {label: "Tổng tiền mua vé"},
            {label: "Ngày nạp tiền gần nhất"},
            {label: "Ngày tạo"},
            {label: "Tỉnh/thành phố"},
            {label: "Quận/huyện"},
            {label: "Địa chỉ"},
            {label: "Email"},
            {label: "Giới tính"},
            {label: "CMT/CCCD"},
            {label: "Trạng thái"},
        ];
        this.filter={
            size: 100,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    getCustomerStatistic(page){
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
        }
        if( this.state.fromCreated!==null ) 
            Object.assign(this.filter,{fromCreated: utils.convertDate(this.state.fromCreated)})
        else delete this.filter.fromCreated;
        if( this.state.toCreated!==null ) 
            Object.assign(this.filter,{toCreated:utils.convertDate(this.state.toCreated)})
        else delete this.filter.toCreated;

        let query = {...this.filter};
        for (let i = 0; i < this.smallOption.length; i++) {
            let key = this.smallOption[i];
            if( !utils.isEmpty(query[key]) ) query[key] = query[`option_${key}`]+query[key]/1000
        }

        customerStatistic(
            query,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({statisticData: result, pageNum : Math.ceil(result.count/this.filter.size)})
            }
        });
    }

    toggleActiveCustomer(index,id,sdt,currentStatus){
        utils.confirm(`Xác nhận ${currentStatus? "KHÓA" : "KÍCH HOẠT"} tài khoản ${sdt}`, (check) => {
            if (check) {
                adjustCustomer(
                    id,
                    {isActive: currentStatus?false:true}
                    ,(err, result) => {
                        if (err) {
                            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                        } else {
                            let state = Object.assign({}, this.state);
                            state.statisticData.rows[index].isActive = result.isActive;
                            this.setState(state);
                        }
                });
            }
        });
    }

    componentDidMount(){
        this.getCustomerStatistic(null);
    }

    render() {
        return(
            <React.Fragment>
                
                <div className='mx-4 p-3 w-120 text-center'>
                    <h2 className="d-inline">
                        {`Tổng số user tìm được: ${this.state.statisticData ? this.state.statisticData.count: null}`}
                    </h2>
                    <Row className='justify-content-center m-0 mt-3'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Ngày tạo tài khoản từ ngày</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromCreated}
                                onChange={date =>this.setState({fromCreated: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 ml-3'>
                            <Label className="d-block">Ngày tạo tài khoản đến ngày</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.toCreated}
                                onChange={date => this.setState({toCreated: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={this.getCustomerStatistic.bind(this,null)}>
                                Tìm kiếm
                            </Button>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="download-table-xls-button d-inline mx-2 "
                                table="thong-ke-khach-hang"
                                filename={`THỐNG KÊ THÔNG TIN KHÁCH HÀNG TỪ ${this.filter.fromCreated||"TỪ ĐẦU"} ĐẾN ${this.filter.toCreated||moment().format('DD-MM-YYYY')}`}
                                sheet={`sheet1`}
                                buttonText="Tải xuống"
                            />
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Filter label="Họ tên"  type="text" name="fullName" action={this.inputOnchange.bind(this)}/>
                        <Filter label="SĐT" type="text" name="phoneNumber" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Số tiền dư trong tài khoản"  type="text" name="totalMoney" action={this.inputOnchange.bind(this)}
                                smallOption={[">","<","="]}
                        />
                        <Filter label="Tổng tiền nạp vào"   type="text" name="totalAddMoney" action={this.inputOnchange.bind(this)}
                                smallOption={[">","<","="]}
                        />
                        <Filter label="Tổng tiền trúng thưởng"  type="text" name="totalWinning" action={this.inputOnchange.bind(this)}
                                smallOption={[">","<","="]}
                        />
                        <Filter label="Tổng tiền rút"   type="text" name="totalWithdrawal" action={this.inputOnchange.bind(this)}
                                smallOption={[">","<","="]}
                        />
                        <Filter label="Tổng tiền mua vé"    type="text" name="totalPayForTicket" action={this.inputOnchange.bind(this)}
                                smallOption={[">","<","="]}
                        />
                        <Filter label="Tỉnh/thành phố"  type="option" name="cityId" optionSelect={arrayCity} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Email"   type="text" name="email" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Giới tính"   type="option" name="gender"  optionSelect={gender} action={this.inputOnchange.bind(this)}/>
                        <Filter label="CMT/CCCD"    type="text" name="idCardNumber" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Trạng thái"  type="text" name="" action={this.inputOnchange.bind(this)}/>
                    </Row>
                </div>
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utils.isEmpty(this.state.statisticData) ? null :
                                    this.state.statisticData.rows.map((row,key,)=>{
                                        return(
                                            <tr key={key}>
                                                <td>{(this.filter.page-1)*this.filter.size+key+1}</td>
                                                <td>{row.fullName || "-"}</td>
                                                <td>{row.phoneNumber || "-"}</td>
                                                <td>{utils.getMoneyFormat(row.wallet.balance) || 0}</td>
                                                <td>{utils.getMoneyFormat(row.totalAddMoney) || 0}</td>
                                                <td>{utils.getMoneyFormat(0)}</td>
                                                <td>{utils.getMoneyFormat(row.totalWinning) || 0}</td>
                                                <td>{utils.getMoneyFormat(row.totalWithdrawal) || 0}</td>
                                                <td>{utils.getMoneyFormat(row.totalPayForTicket) || 0}</td>
                                                <td>{row.nearestAddTime || "-"}</td>
                                                <td>{row.createDate || "-"}</td>
                                                <td>{getNameCityFromCode(row.cityId)||"-"}</td>
                                                <td>{getNameDistrictFromCode(row.districtId)||"-"}</td>
                                                <td>{row.address || "-"}</td>
                                                <td>{row.email || "-"}</td>
                                                <td>{row.gender || "-"}</td>
                                                <td>{row.idCardNumber || "-"}</td>
                                                <td className="cursor-pointer" 
                                                    // onClick={this.toggleActiveCustomer.bind(this,key,row.id,row.phoneNumber,row.isActive)}
                                                >{row["isActive"] ? "Kích hoạt" : "Khóa"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mt-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            {
                                utils.isEmpty(this.state.statisticData) ? null
                                :(
                                    <div className="float-right px-2">
                                        <Button className="mr-1" disabled={this.filter.page===1} onClick={this.getCustomerStatistic.bind(this,"-")}>{`<`}</Button>
                                        {
                                            utils.range(1,10).map((index)=>{
                                                return(
                                                    <Button 
                                                        className={`${this.filter.page === index ? "btn-danger" : null}`}
                                                        hidden={index >  this.state.pageNum}
                                                        onClick={this.getCustomerStatistic.bind(this,index)}
                                                    >
                                                        {index}
                                                    </Button>
                                                )
                                            })
                                        }
                                        <Button hidden={this.filter.page <= 10|| this.filter.page===this.state.pageNum} className="btn-danger">{`${this.filter.page}`}</Button>
                                        <Button hidden={this.state.pageNum<=10} className={`${this.filter.page === this.state.pageNum ? "btn-danger" : null}`} onClick={this.getCustomerStatistic.bind(this,this.state.pageNum)}>{`...${this.state.pageNum}`}</Button>
                                        <Button className="ml-1" disabled={this.filter.page >=  this.state.pageNum} onClick={this.getCustomerStatistic.bind(this,"+")}>{`>`}</Button>
                                    </div>
                                )
                            }
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;

const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');

function listReason(type,intendedFor,callback) {
    axios({
        url: `${config_api.admin}/config/note?typeReason=${type}&intendedFor=${intendedFor}`,
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function createReason(data,callback) {
    axios({
        url: config_api.admin+"/config/note",
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function editReason(data,callback) {
    axios({
        url: config_api.admin+"/config/note",
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function deleteReason(Id,callback) {
    axios({
        url: config_api.admin+"/config/note/"+Id,
        method: 'DELETE',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports = {
    listReason : listReason,
    createReason : createReason,
    editReason : editReason,
    deleteReason : deleteReason
};
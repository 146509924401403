import React from "react";
import utils from "../../../utils";
import { Col, Row,  Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupAddon, Input, FormGroup, Label } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import { Edit,  Lock, Unlock } from "react-feather";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "../../../components/Notification";
const {getNameDistrictFromCode} = require("../../../config/listDistrict");
const {getNameCityFromCode} = require("../../../config/listCity");
const {arrayCity} = require("../../../config/city");
const apiStore = require("./../api/apiStore");
const apiLocation = require("./../api/apiLocation");

class RowStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            showModal: {
                editStore: false,
            },
            // edit staff
            admin_agency__RowStoreTable__storeName: this.props.data.storeName,
            admin_agency__RowStoreTable__address__city: this.props.data.cityId,
            admin_agency__RowStoreTable__address__districts: this.props.data.districtId,
            admin_agency__RowStoreTable__address: this.props.data.address,
            admin_agency__RowStoreTable__storePhone: this.props.data.storePhone,
            admin_agency__RowStoreTable__owner: this.props.data.owner,
            admin_agency__RowStoreTable__ownerIdCardNumber: this.props.data.ownerIdCardNumber,
            admin_agency__RowStoreTable__ownerPhoneNumber: this.props.data.ownerPhoneNumber,

            checkdistricts: true,
            getDistrict: null,
            listDistrict: [],
        };
    }

    inputOnchange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    toggle(component1, component2, value) {
        let state = Object.assign({}, this.state);
        if (component2) {
            if (value) {
                state[component1][component2] = value;
                this.setState(state);
            } else {
                state[component1][component2] = !state[component1][component2];
                this.setState(state);
            }
        } else {
            if (value) {
                state[component1] = value;
                this.setState(state);
            } else {
                state[component1] = !state[component1];
                this.setState(state);
            }
        }
    }

    handClickSuccessModalEditStore() {
        let data = {};
        //check data
        if (!this.state.checkdistricts) {
            Notification("error", "Chọn lại Quận/huyện");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__storeName)) {
            Notification("error", "Điền đầy đủ thông tin", "Tên cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__address__city)) {
            Notification("error", "Điền đầy đủ thông tin", "Tỉnh/Thành phố không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__address__districts)) {
            Notification("error", "Điền đầy đủ thông tin", "Quận/huyện không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__address)) {
            Notification("error", "Điền đầy đủ thông tin", "Địa chỉ chi tiết không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__owner)) {
            Notification("error", "Điền đầy đủ thông tin", "Tên chủ cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__ownerIdCardNumber)) {
            Notification("error", "Điền đầy đủ thông tin", "Số CMND/CCCD chủ cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__ownerPhoneNumber)) {
            Notification("error", "Điền đầy đủ thông tin", "Số điện thoại chủ cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__storePhone)) {
            Notification("error", "Điền đầy đủ thông tin", "Số điện thoại không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__ownerPhoneNumber.length !== 10)) {
            Notification("error", "Sai thông tin", "Số điện thoại chủ cửa hàng không đúng định dạng");
            return null;
        }
        if (utils.isEmpty(this.state.admin_agency__RowStoreTable__storePhone.length !== 10)) {
            Notification("error", "Sai thông tin", "Số điện thoại không đúng định dạng");
            return null;
        }
        // them data bi thay doi 
        if (this.state.admin_agency__RowStoreTable__storeName !== this.state.data.storeName) data = Object.assign({ storeName: this.state.admin_agency__RowStoreTable__storeName }, data);
        if (this.state.admin_agency__RowStoreTable__storePhone !== this.state.data.storePhone) data = Object.assign({ storePhone: this.state.admin_agency__RowStoreTable__storePhone }, data);
        if (this.state.admin_agency__RowStoreTable__address !== this.state.data.address) data = Object.assign({ address: this.state.admin_agency__RowStoreTable__address }, data);
        if (this.state.admin_agency__RowStoreTable__address__city !== this.state.data.cityId) data = Object.assign({ cityId: this.state.admin_agency__RowStoreTable__address__city }, data);
        if (this.state.admin_agency__RowStoreTable__owner !== this.state.data.owner) data = Object.assign({ owner: this.state.admin_agency__RowStoreTable__owner }, data);
        if (this.state.admin_agency__RowStoreTable__ownerIdCardNumber !== this.state.data.ownerIdCardNumber)
            data = Object.assign({ ownerIdCardNumber: this.state.admin_agency__RowStoreTable__ownerIdCardNumber }, data);
        if (this.state.admin_agency__RowStoreTable__ownerPhoneNumber !== this.state.data.ownerPhoneNumber)
            data = Object.assign({ ownerPhoneNumber: this.state.admin_agency__RowStoreTable__ownerPhoneNumber }, data);
        if (this.state.admin_agency__RowStoreTable__address__districts !== this.state.data.districtId)
            data = Object.assign({ districtId: this.state.admin_agency__RowStoreTable__address__districts }, data);
        apiStore.editStore(this.state.data.id, data, (err, result) => {
            if (err) {
                switch (err.data._error_message) {
                    case "Invalid phone number":
                        Notification("error", "Lỗi", "Định dạng số điện thoại bị sai");
                        break;
                    default:
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                        break;
                }
            } else {
                
                this.props.handleUpdateStore();
                Notification("success", "Chỉnh sửa thông tin cửa hàng thành công");
                let state = Object.assign({}, this.state);
                state.data = result;
                state.showModal.editStore = false;
                this.setState(state);
                if (utils.isEmpty(this.state.getDistrict)) {
                    this.getDistrict(this.state.data.districtId);
                }
            }
        });
    }

    getDistrict(districtId) {
        apiLocation.getDistrict(districtId, (err, result) => {
            if (err) {
                this.setState({ getDistrict: null });
            } else {
                this.setState({ getDistrict: result });
            }
        });
    }

    listDistrict(cityId) {
        apiLocation.listDistrict(cityId, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                let state = Object.assign({}, this.state);
                state.listDistrict = Object.keys(result).map(function(val, key) {
                    return { value: result[val].code, label: result[val].name };
                });
                this.setState(state);
            }
        });
    }

    componentDidMount() {
        if (!utils.isEmpty(this.state.data.districtId)) this.getDistrict(this.state.data.districtId);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
        });
    }

    render() {
        return (
            <React.Fragment>
                {/* Modal Edit store*/}
                <Modal isOpen={this.state.showModal.editStore}>
                    <ModalHeader>Sửa thông tin cửa hàng</ModalHeader>
                    <ModalBody>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Tên cửa hàng</InputGroupAddon>
                            <Input name='admin_agency__RowStoreTable__storeName' defaultValue={this.state.data.storeName} onChange={this.inputOnchange.bind(this)} />
                        </InputGroup>
                        <Row>
                            <Col md='3'>
                                <FormGroup>
                                    <Label>Tỉnh/Thành phố</Label>
                                    <Select
                                        defaultValue= {{ value: this.state.data.cityId, label: this.state.data.cityName }}
                                        name='admin_agency__RowStoreTable__address__city'
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                        options={this.props.listCity}
                                        onChange={value => {
                                            this.listDistrict(value.value);
                                            this.setState({ admin_agency__RowStoreTable__address__city: value.value,checkdistricts: false });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='3'>
                                <FormGroup>
                                    <Label>Quận/huyện</Label>
                                    <Select
                                        defaultValue={this.state.getDistrict ? { value: this.state.getDistrict.code, label: this.state.data.districtName } : null}
                                        name='admin_agency__RowStoreTable__address__districts'
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                        options={this.state.listDistrict}
                                        onChange={value => {
                                            this.setState({ admin_agency__RowStoreTable__address__districts: value.value,checkdistricts: true });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <FormGroup>
                                    <Label>Địa chỉ chi tiết</Label>
                                    <Input name='admin_agency__RowStoreTable__address' defaultValue={this.state.data.address} onChange={this.inputOnchange.bind(this)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Tên chủ cửa hàng</InputGroupAddon>
                            <Input name='admin_agency__RowStoreTable__owner' defaultValue={this.props.data.owner} onChange={this.inputOnchange.bind(this)} />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Số CMND/CCCD chủ cửa hàng</InputGroupAddon>
                            <Input 
                                name='admin_agency__RowStoreTable__ownerIdCardNumber' 
                                defaultValue={this.props.data.ownerIdCardNumber} 
                                onKeyUp={(event) => {
                                    if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                }}
                                onChange={this.inputOnchange.bind(this)} 
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>SĐT chủ cửa hàng</InputGroupAddon>
                            <Input
                                type='text'
                                name='admin_agency__RowStoreTable__ownerPhoneNumber'
                                maxLength='10'
                                defaultValue={this.props.data.ownerPhoneNumber}
                                onKeyUp={(event) => {
                                    if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                }}
                                onChange={this.inputOnchange.bind(this)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Số điện thoại cửa hàng</InputGroupAddon>
                            <Input
                                type='text'
                                name='admin_agency__RowStoreTable__storePhone'
                                maxLength='10'
                                defaultValue={this.state.data.storePhone}
                                onKeyUp={(event) => {
                                    if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                }}
                                onChange={this.inputOnchange.bind(this)}
                            />
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.toggle.bind(this, "showModal", "editStore", null)}>
                            Hủy bỏ
                        </Button>
                        <Button color='success' onClick={this.handClickSuccessModalEditStore.bind(this)}>
                            Lưu
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.data.isActive ? (
                    <Unlock className='text-success float-right mr-2' onClick={this.props.handActiveStore.bind(this, false, this.state.data.id)} />
                ) : (
                    <Lock className='text-danger float-right mr-2' onClick={this.props.handActiveStore.bind(this, true, this.state.data.id)} />
                )}
                {this.state.data.isActive ? <Edit className='mr-2 text-info float-right' onClick={()=>{this.toggle("showModal", "editStore");this.listDistrict(this.state.data.cityId) }} /> : null}
            </React.Fragment>
        );
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listStaff: [],
            listStore: [],
            listCity: arrayCity,
            listDistrict: [],

            // create staff
            storeManagement__Main__createStore__storeName: null,
            storeManagement__Main__createStore__address_city: null,
            storeManagement__Main__createStore__address_districts: null,
            storeManagement__Main__createStore__address: null,
            storeManagement__Main__createStore__storePhone: null,
            storeManagement__Main__createStore__owner: null,
            storeManagement__Main__createStore__ownerIdCardNumber: null,
            storeManagement__Main__createStore__ownerPhoneNumber: null,

            showModal: {
                createStore: false,
                deleteStaff: false,
            },
            checkdistricts: false,
        };
        this.idStoreDelete = null;
        this.handActiveStore = this.handActiveStore.bind(this);
        this.handleUpdateStore = this.handleUpdateStore.bind(this);
    }

    toggle(component1, component2, value) {
        let state = utils.getRawData(this.state);
        if (component2) {
            if (value) {
                state[component1][component2] = value;
                this.setState(state);
            } else {
                state[component1][component2] = !state[component1][component2];
                this.setState(state);
            }
        } else {
            if (value) {
                state[component1] = value;
                this.setState(state);
            } else {
                state[component1] = !state[component1];
                this.setState(state);
            }
        }
    }

    inputOnchange(event) {
        switch (event.target.name) {
            case "storeManagement__Main__createStore__address_city":
                this.setState({
                    [event.target.name]: event.target.value,
                    checkdistricts: false
                });
                break;
            case "storeManagement__Main__createStore__address_districts":
                this.setState({
                    [event.target.name]: event.target.value,
                    checkdistricts: false
                });
                break;
        
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
                break;
        }
        
    }

    handCreateStore() {
        if (!this.state.checkdistricts) {
            Notification("error", "Chọn lại Quận/huyện");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__storeName)) {
            Notification("error", "Điền đầy đủ thông tin", "Tên cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__address_city)) {
            Notification("error", "Điền đầy đủ thông tin", "Tỉnh/Thành phố không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__address_districts)) {
            Notification("error", "Điền đầy đủ thông tin", "Quận/huyện không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__address)) {
            Notification("error", "Điền đầy đủ thông tin", "Địa chỉ chi tiết không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__owner)) {
            Notification("error", "Điền đầy đủ thông tin", "Tên chủ cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__ownerIdCardNumber)) {
            Notification("error", "Điền đầy đủ thông tin", "Số CMND/CCCD chủ cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__ownerPhoneNumber)) {
            Notification("error", "Điền đầy đủ thông tin", "Số điện thoại chủ cửa hàng không được để trống");
            return null;
        }
        if (utils.isEmpty(this.state.storeManagement__Main__createStore__storePhone)) {
            Notification("error", "Điền đầy đủ thông tin", "Số điện thoại không được để trống");
            return null;
        }

        let data = {
            storeName: this.state.storeManagement__Main__createStore__storeName,
            address: this.state.storeManagement__Main__createStore__address,
            cityId: this.state.storeManagement__Main__createStore__address_city,
            districtId: this.state.storeManagement__Main__createStore__address_districts,
            owner: this.state.storeManagement__Main__createStore__owner,
            ownerIdCardNumber: this.state.storeManagement__Main__createStore__ownerIdCardNumber,
            ownerPhoneNumber: this.state.storeManagement__Main__createStore__ownerPhoneNumber,
            storePhone: this.state.storeManagement__Main__createStore__storePhone,
        };
        apiStore.createStore(data, (err, result) => {
            if (err) {
                switch (err.data._error_message) {
                    case "Failed to update store":
                        Notification("error", "Lỗi", "Cửa hàng đã tồn tại");
                        break;
                    case "Data too long for column 'address' at row 1":
                        Notification("error", "Lỗi", "Tên cửa hàng quá dài");
                        break;
                    case "Invalid store phone number":
                        Notification("error", "Lỗi", "Sai định dạng số điện thoại");
                        break;
                    default:
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                        break;
                }
            } else {
                Notification("success", "Tạo cửa hàng thành công");
                let state = Object.assign({}, this.state);
                apiLocation.getDistrict(result.districtId, (err, res) => {
                    if (err) {
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                    } else {
                        result.addressShow = `${result.address}, ${res.path}`
                        state.listStore.push(result);
                        state.showModal.createStore = false;
                        this.setState(state);
                    }
                });
            }
        });
    }

    handDeleteStore() {
        if (this.idStoreDelete) {
            apiStore.deleteStore(this.idStoreDelete, (err, result) => {
                if (err) {
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
                } else {
                    Notification("success", "Xóa cửa hàng thành công");
                    let state = utils.getRawData(this.state);
                    state.listStore = state.listStore.filter(Store => Store.id != this.idStoreDelete);
                    state.showModal.deleteStaff = false;
                    this.idStoreDelete = null;
                    this.setState(state);
                }
            });
        }
    }

    handActiveStore(status, id) {
        apiStore.editStore(id, { isActive: status }, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                Notification("success", "Chỉnh sửa cửa hàng thành công");
                this.handleUpdateStore();
            }
        });
    }

    handleUpdateStore() {
        apiStore.listStore((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                result.map((value) => {
                    value.districtName = getNameDistrictFromCode(value.districtId);
                    value.cityName = getNameCityFromCode(value.cityId);
                    value.addressShow = `${value.address}, ${value.districtName}, ${value.cityName}`
                    return value;
                });
                this.setState({listStore: result});
            }
        });
    }

    openModalDeleteStaff(id) {
        this.idStoreDelete = id;
        this.toggle("showModal", "deleteStaff", null);
    }

    listDistricts(cityId) {
        apiLocation.listDistrict(cityId, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                let state = Object.assign({}, this.state);
                state.listDistrict = Object.keys(result).map(function(val, key) {
                    return { value: result[val].code, label: result[val].name_with_type };
                });
                this.setState(state);
            }
        });
    }

    componentDidMount() {
        // get info store
        this.handleUpdateStore();
    }

    render() {
        const tableColumns = [
            {
                dataField: "storeName",
                text: "Tên cửa hàng",
                filter: textFilter({
                    placeholder: "Nhập tên cửa hàng",
                }),
            },
            {
                dataField: "addressShow",
                text: "Địa chỉ của cửa hàng",
                filter: textFilter({
                    placeholder: "Nhập địa chỉ cửa hàng",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
                headerStyle: (colum, colIndex) => {
                    return { width: "350px", text: "center" };
                },
            },
            {
                dataField: "owner",
                text: "Chủ cửa hàng",
                filter: textFilter({
                    placeholder: "Nhập tên chủ cửa hàng",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "ownerIdCardNumber",
                text: "Số CMND/CCCD",
                filter: textFilter({
                    placeholder: "Nhập số CMTND/CCCD",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: "ownerPhoneNumber",
                text: "SĐT chủ cửa hàng",
                filter: textFilter({
                    placeholder: "Nhập SĐT chủ cửa hàng",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                dataField: `storePhone`,
                text: "SĐT cửa hàng",
                sort: true,
                filter: textFilter({
                    placeholder: "Nhập SĐT cửa hàng",
                }),
                formatter: function Formatter(cell, row) {
                    if (cell === null) {
                        return "---";
                    } else {
                        return cell;
                    }
                },
            },
            {
                headerFormatter: (column, colIndex) => {
                    return (
                        <Button className='float-center m-2 btn-suscess text-white' color='success' onClick={this.toggle.bind(this, "showModal", "createStore", null)}>
                            Thêm cửa hàng
                            <FontAwesomeIcon className='ml-2' icon={faStore} />
                        </Button>
                    );
                },
                formatter: (cell, row, rowIndex) => {
                    return (
                        <RowStore
                            listStore={this.state.listStore}
                            listCity={this.state.listCity}
                            openModalDeleteStaff={this.openModalDeleteStaff.bind(this)}
                            data={row}
                            handActiveStore={this.handActiveStore}
                            handleUpdateStore={this.handleUpdateStore}
                        />
                    );
                },
            },
        ];
        const rowStyle2 = (row, rowIndex) => {
            const style = {};
            if (this.state.listStore[rowIndex].isActive === false) {
                style.backgroundColor = "#cccccc";
                style.fontWeight = "bold";
                style.color = "white";
            }
            return style;
        };
        return (
            <React.Fragment>
                <div >
                    {/* Modal Create store*/}
                    <Modal isOpen={this.state.showModal.createStore}>
                        <ModalHeader>Tạo đại lý</ModalHeader>
                        <ModalBody>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Tên đại lý</InputGroupAddon>
                                <Input name='storeManagement__Main__createStore__storeName' onChange={this.inputOnchange.bind(this)} />
                            </InputGroup>
                            <Row>
                                <Col md='3'>
                                    <FormGroup>
                                        <Label>Tỉnh/Thành phố</Label>
                                        <Select
                                            name='storeManagement__Main__createStore__address_city'
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            options={this.state.listCity}
                                            onChange={value => {
                                                this.listDistricts(value.value);
                                                this.setState({ storeManagement__Main__createStore__address_city: value.value,checkdistricts: false });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md='3'>
                                    <FormGroup>
                                        <Label>Quận/huyện</Label>
                                        <Select
                                            name='storeManagement__Main__createStore__address_districts'
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            options={this.state.listDistrict}
                                            onChange={value => {
                                                this.setState({ storeManagement__Main__createStore__address_districts: value.value,checkdistricts:true });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>Địa chỉ chi tiết</Label>
                                        <Input name='storeManagement__Main__createStore__address' onChange={this.inputOnchange.bind(this)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Tên chủ cửa hàng</InputGroupAddon>
                                <Input name='storeManagement__Main__createStore__owner' onChange={this.inputOnchange.bind(this)} />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Số CMND/CCCD chủ cửa hàng</InputGroupAddon>
                                <Input 
                                    name='storeManagement__Main__createStore__ownerIdCardNumber' 
                                    onChange={this.inputOnchange.bind(this)} 
                                    onKeyUp={(event) => {
                                        if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>SĐT chủ cửa hàng</InputGroupAddon>
                                <Input
                                    type='text'
                                    name='storeManagement__Main__createStore__ownerPhoneNumber'
                                    maxLength='10'
                                    onKeyUp={(event) => {
                                        if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                    }}
                                    onChange={this.inputOnchange.bind(this)}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Số điện thoại cửa hàng</InputGroupAddon>
                                <Input
                                    type='text'
                                    name='storeManagement__Main__createStore__storePhone'
                                    maxLength='10'
                                    onKeyUp={(event) => {
                                        if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                    }}
                                    onChange={this.inputOnchange.bind(this)}
                                />
                            </InputGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={this.toggle.bind(this, "showModal", "createStore", null)}>
                                Hủy bỏ
                            </Button>
                            <Button color='success' onClick={this.handCreateStore.bind(this)}>
                                Lưu
                            </Button>
                        </ModalFooter>
                    </Modal>

                    {/* Modal Delete account*/}
                    <Modal isOpen={this.state.showModal.deleteStaff}>
                        <ModalHeader>Xóa cửa hàng</ModalHeader>
                        <ModalBody>Xác nhận xóa cửa hàng</ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={this.toggle.bind(this, "showModal", "deleteStaff", null)}>
                                Hủy bỏ
                            </Button>
                            <Button color='success' onClick={this.handDeleteStore.bind(this)}>
                                Lưu
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <div className="overflow-x-auto table-overflow-x">
                        <BootstrapTable
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.listStore}
                            columns={tableColumns}
                            filter={filterFactory()}
                            rowStyle={rowStyle2}
                        />    
                    </div>
                    
                </div>
            </React.Fragment>
        );
    }
}

export default Main;

import React from "react";
import {isEmpty,convertMoneyFomat} from '../../utils'
import ModalCamera from "../../components/ModalCamera";
import Notification from "../../components/Notification";
import "./css/budget.css"
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from 'reactstrap';
import { InputGroupAddon } from 'reactstrap';
import { InputGroup } from 'reactstrap';
import { Input } from 'reactstrap';
import  Select from 'react-select';
import { Button } from "react-bootstrap";
import { Modal } from 'reactstrap';
import { ModalHeader } from 'reactstrap';
import { ModalFooter } from 'reactstrap';

const {listPos} = require('../Admin/api/apiPos')
const {increaseMoneyToPos} = require('./api/apiBudget')

class ModalConfirm extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.show}>
                    <ModalHeader>{this.props.text}</ModalHeader>
                    <ModalFooter>
                        <Button className="btn-secondary" onClick={this.props.handleReject}>
                        Hủy bỏ
                        </Button>
                        <Button className="btn-success" onClick={this.props.handleSucces}>
                        Xác nhận
                        </Button>
                    </ModalFooter>
                </Modal>
        );
    }
}

class Budget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            money: 0,
            imgSrc: null,
            imgLink: null,
            posCode: null,
            disabledShooting: false,
            listPos: [],
        }
    }

    moneyChange(event) {
        // let gteZero = event.target.value.search("-")===-1;
        let amount = event.target.value.replace(/[^\d]/g, "");
        if (isEmpty(amount))
            amount = 0;
        try {
            // amount = gteZero ? parseInt(amount) : parseInt(amount*(-1));
            amount = parseInt(amount);
            if (Number.isNaN(amount))
                return;
            this.setState({[event.target.name]: amount});
            return;
        } catch (e) {
            return;
        }
    }

    handleShootingTicket(id,imgSrc, imgLink) {
        this.setState({
            imgSrc: imgSrc, 
            imgLink:imgLink,
            disabledShooting: true,
        });
    }

    handleRemovePhoto(id) {
        this.setState({
            imgSrc: null, 
            imgLink:null,
            disabledShooting: false,
        });
    }

    handleIncreaseMoneyToPos(){
        let data = {
            posCode: this.state.posCode,
            value: this.state.money,
            image: this.state.imgLink
        };
        increaseMoneyToPos(data,(err,)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                Notification("success", "Nạp tiền thành công");
                this.clear();
            }
        });
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    openModal(){
        if(isEmpty(this.state.money) || this.state.money===0) return Notification("error","Số tiền không được để trống");
        if(isEmpty(this.state.posCode)) return Notification("error","Mã pos không được để trống");
        if(isEmpty(this.state.imgLink)) return Notification("error","Ảnh nạp tiền không được để trống");
        this.toggle("showModalConfirm")
    }

    clear(){
        this.setState({
            money: 0,
            imgSrc: null,
            imgLink: null,
            posCode: null,
            disabledShooting: false,
            showModalConfirm: false,
        })
    }

    componentDidMount(){
        if(isEmpty(this.state.listPos)){
            listPos({isActive:true},(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    this.setState({
                        listPos : result.map(pos=>{
                            pos = {label: pos.code, value: pos.id}
                            return pos
                        })
                    });
                }
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalConfirm
                    show = {this.state.showModalConfirm}
                    text = {`Xác nhận nạp ${this.state.money.getMoneyFormat()} cho POS ${this.state.posCode}`}
                    handleSucces = {this.handleIncreaseMoneyToPos.bind(this)}
                    handleReject = {this.toggle.bind(this,"showModalConfirm")}
                />
                <Card>
                    <CardHeader>
                        <CardTitle className="text-center bg-gray m-0 p-1">
                            NẠP TIỀN VÀO POS
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="6">
                                <InputGroup size='lg' className='mt-3'>
                                    <InputGroupAddon addonType={"append"}>Số tiền</InputGroupAddon>
                                    <Input
                                        type='text'
                                        name='money'
                                        onChange={this.moneyChange.bind(this)}
                                        value={this.state.money.getMoneyFormat()}
                                        maxLength='15'
                                    />
                                </InputGroup>
                                <Select
                                    name='posCode'
                                    className='w-100 mt-3'
                                    placeholder="Chọn mã pos"
                                    classNamePrefix='react-select'
                                    options={this.state.listPos || []}
                                    onChange={(value)=>this.setState({posCode: value.label})}
                                />
                                <div className="mt-3 text-center">
                                    <Button className="btn-success mx-2 btn-lg" onClick={this.openModal.bind(this)}>Nạp tiền</Button>
                                    <Button className="btn-danger mx-2 btn-lg" onClick={this.clear.bind(this)}>Xóa</Button>
                                </div>
                            </Col>
                            <Col md="6" className=" text-center">
                                <ModalCamera
                                    label="Ảnh hóa đơn"
                                    id="rejectTicket"
                                    folder = "wallet"
                                    keyImg="front"
                                    imgSrc = {this.state.imgSrc}
                                    buttonSelectFile = {true}
                                    disabledShooting = {this.state.disabledShooting}
                                    actionWhenReceiveData = {this.handleShootingTicket.bind(this)}
                                    actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default Budget;
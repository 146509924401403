import React from "react";
import { Button } from "reactstrap";
import Notification from './Notification'

class ButtonTimeout extends React.Component {
  constructor(props) {
    super(props);
    this.state={
        enable: true
    }
  }

  onClick(){
      if(this.state.enable){
        this.setState({enable: true},()=>{
            this.props.onClick();
            setTimeout(
                ()=>{this.setState({enable: true});},
                this.props.timeout || 500
            )
        });
      } else{
        Notification('warning',"Thao tác bị loại bỏ do trùng lặp");
      }
  }

  render() {
    return (
      <Button 
        id= {this.props.id}
        className= {this.props.className}
        onClick= {this.onClick.bind(this)}
        style= {this.props.style}
        disabled= {this.props.disabled}
        color= {this.props.color}
      >
        {this.props.children}
      </Button>
    )
  }
}

export default ButtonTimeout
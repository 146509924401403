import React from "react";
import {
    Col, Row, Table,
    Button,
    InputGroup, InputGroupAddon, Input,CustomInput,
    Modal,ModalBody,ModalHeader,ModalFooter,
} from "reactstrap";
import {  Search,} from "react-feather";
import "react-tabs/style/react-tabs.css";
import ModalCamera from "../../components/ModalCamera"
import utils from "../../utils";
import Notification from "../../components/Notification";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const apiAccountant = require("./api/apiAccountant");
const getHistory = require("./api/apiHistory");
const apiConfig = require("../apiGeneral/apiConfig");
const actionRechargeOnDay = require("../../config/config").rechargeOnDay;

const ModalImage = (props) => {
    return (
        <React.Fragment>
            <Modal isOpen={props.isOpen} centered className="modal-camera" size="lg">
                <ModalHeader toggle={props.toggle}>
                    {props.label}
                </ModalHeader>
                <ModalBody className="text-center">
                    <img src={props.src}  height={480} style={{maxWidth:"100%"}}/>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
};

class Withdraw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRequest:true,
            reason: null,

            srcProof: null,

            imgSrc: null,
            imgLink: null,

            historyCustomer: [],

            show_imageProof: false,
            show_reason:false,
            amountMax : null,

            historyPage : 1,
            actionFindter: null
        }
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    handleShooting(id,imgSrc,imgLink) {
        this.setState({imgSrc:imgSrc,imgLink: imgLink});
    }

    handleRemovePhoto(id) {
        this.setState({imgSrc: null,imgLink: null});
    }


    handleTransferBanking() {
        utils.confirm(`Xác nhận đã chuyển <span class="money-title">${utils.convertMoneyFomat(this.props.dataBanking.amount)} đồng</span> vào tài khoản <span class="content-title-bold">${this.props.dataBanking.information.bankIdNumber}</span>`, (check) => {
            if(check){
                let data = {
                    status:"1",
                    proof: this.state.imgLink
                }
                apiAccountant.update(this.props.dataBanking.id,data,(err)=>  {
                    if(err){
                        Notification("error", "Lỗi", "Không thể xác nhận đã chuyển tiền. Ghi lại thông tin yêu cầu và báo cáo với quản trị viên");
                    }
                    else{
                        Notification("success" ,"Xác nhận thành công.");
                        this.props.toggle("clearDataHandling");
                    }
                });
            }
        })
    }

    checkEmptyField(){
        if(utils.isEmpty(this.state.imgLink)){
            Notification("error","Chưa đủ thông tin","Chưa chụp hóa đơn");
        }
        else{
            this.handleTransferBanking();
        }
    }

    getHistoryCustomer(dataCustommer,page,actionFindter){
        if(utils.isEmpty(dataCustommer)){
            Notification("error", "Không thể mở lịch sử","Không có thông tin khách hàng");
        }
        else{
            getHistory.getHistory(dataCustommer.phoneNumber,page,actionFindter,(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    this.setState({historyCustomer: result})
                }
            })
        }
    }

    handClickHistoryCustomer(){
        if(utils.isEmpty(this.state.historyCustomer)){
            this.getHistoryCustomer(this.props.dataBanking.customer,this.state.historyPage,this.state.actionFindter);
        } 
        else{
            this.setState({historyCustomer:[]})
        }
    }

    handPrePageHistory(){
        if(this.state.historyPage>1){
            this.getHistoryCustomer(this.props.dataBanking.customer,this.state.historyPage-1,this.state.actionFindter);
            this.setState({historyPage: this.state.historyPage-1})
        }
    }

    handNextPageHistory(){
        this.getHistoryCustomer(this.props.dataBanking.customer,this.state.historyPage+1,this.state.actionFindter);
        this.setState({historyPage: this.state.historyPage+1})
    }

    handChangeOptionHistory(event){
        if (event.target.value === "Nạp tiền"){
            this.getHistoryCustomer(this.props.dataBanking.customer,1,"fluctuationTypeId[]=3&fluctuationTypeId[]=4&fluctuationTypeId[]=5");
            this.setState({historyPage: 1, actionFindter:"fluctuationTypeId[]=3&fluctuationTypeId[]=4&fluctuationTypeId[]=5"})
        }else if (event.target.value === "Rút tiền"){
            this.getHistoryCustomer(this.props.dataBanking.customer,1,"fluctuationTypeId[]=2&fluctuationTypeId[]=9");
            this.setState({historyPage: 1, actionFindter:"fluctuationTypeId[]=2&fluctuationTypeId[]=9"})
        }else if (event.target.value === "Mua vé"){
            this.getHistoryCustomer(this.props.dataBanking.customer,1,"fluctuationTypeId[]=1");
            this.setState({historyPage: 1, actionFindter:"fluctuationTypeId[]=1"})
        }else if (event.target.value === "Tất cả hoạt động"){
            this.getHistoryCustomer(this.props.dataBanking.customer,1);
            this.setState({historyPage: 1, actionFindter:null})
        }
    }

    handleChange(event) {
        if (event.target.name === "money") {
            let amount = event.target.value.replace(/\./g, "");
            if (utils.isEmpty(amount))
                amount = 0;
            try {
                amount = parseInt(amount);
                if (Number.isNaN(amount))
                    return;
                this.setState({money: amount});
                return;
            } catch (e) {
                return;
            }
        }
        this.setState({
            [event.target.name]: event.target.value,
            isRequest: false
        })
    }

    inputOnChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    uploadReason(){
        if(utils.isEmpty(this.state.reason)){
            Notification("error","Lỗi","Lý do hủy yêu cầu không được để trống");
        }
        else{
            apiAccountant.update(this.props.dataBanking.id,{status:"2",reason: this.state.reason},(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    Notification("success", "Hủy yêu cầu thành công",);
                    let state = Object.assign({}, this.state);
                    state.idBanking=null;
                    state.money=0;
                    state.phone_number="";
                    state.imgSrc=null;
                    state.imgLink=null;
                    state.dataCustommer=null;
                    state.srcProof=null;
                    state.isRequest = false;
                    state.note = null;
                    state.historyCustomer=[];
                    state.reason=null;
                    state.show_reason=false;
                    this.setState(state);
                    this.props.toggle("clearDataHandling");
                }
            });
        }
    }

    handTakeAway(){
        if(!utils.isEmpty(this.props.dataBanking)){
            let state = Object.assign({}, this.state);
            state.idBanking=null;
            state.money=0;
            state.phone_number="";
            state.imgSrc=null;
            state.imgLink=null;
            state.dataCustommer=null;
            state.srcProof=null;
            state.isRequest = false;
            state.note = null;
            state.historyCustomer=[];
            state.reason=null;
            state.show_reason=false;
            this.setState(state);
            this.props.toggle("takeAway");
        }
    }

    componentDidMount(){
        apiConfig.getConfig((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({amountMax: result});
            }
        });
    }



    render() {
        return (
            <React.Fragment>
                <ModalImage id={"imgProof"}
                            label = {"Ảnh chuyển tiền ngân hàng"}
                            isOpen={this.state.show_imageProof}
                            src={utils.getURLImage(this.state.srcProof)}
                            toggle={this.toggle.bind(this, "show_imageProof")}
                />
                <Modal isOpen={this.state.show_reason}>
                    <ModalHeader>Lý do hủy yêu cầu</ModalHeader>
                    <ModalBody>
                        <CustomInput
                            style={{fontSize:"20px"}}
                            size = "10"
                            type="select"
                            id="reason"
                            name="reason"
                            onChange = { this.inputOnChange.bind(this) }
                            multiple
                        >
                            {
                                this.props.listReason.map((value,index)=>{
                                    return(
                                        <option key={index} className="border-bottom"> {value.reason}</option>
                                    )
                                })
                            }
                        </CustomInput>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle.bind(this,"show_reason",null,null)}>
                        Hủy bỏ
                        </Button>
                        <Button color="success" onClick={this.uploadReason.bind(this)}>
                        Xác nhận
                        </Button>
                    </ModalFooter>
                </Modal>
                <Col className={this.props.className}>
                    <Row className="_tittle-blue">
                        <Search/> THÔNG TIN KHÁCH HÀNG RÚT TIỀN
                    </Row>
                    <Row className= {"mt-2 w-lg-40"}>
                        <InputGroup style={{height:"35px"}}>
                            <InputGroupAddon addonType={"append"}>Số ĐT</InputGroupAddon>
                            <Input type="text"
                                name="phone_number"
                                className="h-100"
                                value={this.props.dataBanking.customer.phoneNumber}
                                maxLength="10"
                                readOnly= {this.state.isRequest}
                                onKeyUp={(event) => {
                                    if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                    if (event.key === "Enter") {
                                        this.handleSearch();
                                    }
                                }}
                            />
                        </InputGroup>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Row>
                                <Table bordered>
                                    <thead>
                                    <tr>
                                        <th style={{width:"20%"}}>Họ tên</th>
                                        <th style={{width:"20%"}}>Số điện thoại</th>
                                        <th style={{width:"20%"}}>Số CMND</th>
                                        <th style={{width:"20%"}}>Số dư tài khoản</th>
                                        <th style={{width:"20%"}}>Số dư khả dụng</th>
                                        <th style={{width:"20%"}}>Trúng thưởng</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{this.props.dataBanking.customer.fullName}</td>
                                        <td>{this.props.dataBanking.customer.phoneNumber}</td>
                                        <td>{this.props.dataBanking.customer.idCardNumber}</td>
                                        <td>{utils.convertMoneyFomat(this.props.dataBanking.customer.wallet.balance)}</td>
                                        <td>{utils.convertMoneyFomat(this.props.dataBanking.customer.wallet.availableBalance)}</td>
                                        <td>{utils.convertMoneyFomat(this.props.dataBanking.customer.wallet.winingBalance)}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <Table bordered>
                                    <thead>
                                    <tr>
                                        <th style={{width:"25%"}}>Số tài khoản</th>
                                        <th style={{width:"25%"}}>Ngân hàng</th>
                                        <th style={{width:"25%"}}>Chủ tài khoản</th>
                                        <th style={{width:"25%"}}>Số tiền</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{this.props.dataBanking.information.bankIdNumber}</td>
                                        <td>{this.props.dataBanking.information.bankName}</td>
                                        <td>{this.props.dataBanking.information.ownerName}</td>
                                        <td>{utils.convertMoneyFomat(this.props.dataBanking.amount)}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            <Row className="mt-1">
                                    <Col className=" text-center p-2">
                                        <ModalCamera
                                            label="Ảnh hóa đơn"
                                            id="imageBill"
                                            imgSrc = {this.state.imgSrc}
                                            folder = "wallet"
                                            actionWhenReceiveData = {this.handleShooting.bind(this)}
                                            actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                            buttonSelectFile = {true}
                                        />
                                    </Col>
                                    
                            </Row>
                            
                            <Row className="mt-4 text-center justify-content-center">
                                <Button className="mr-2 p-3  w-20 btn-success"
                                        onClick={this.checkEmptyField.bind(this)}
                                >
                                    Xác nhận đã chuyển tiền
                                </Button>
                                <Button className="mr-2 p-3  w-20"
                                        onClick={this.handClickHistoryCustomer.bind(this)}
                                >
                                    {utils.isEmpty(this.state.historyCustomer)?"Lịch sử khách hàng":"Đóng lịch sử khách hàng"}
                                </Button>
                                <Button className="mr-2 p-3 w-20 btn-warning" 
                                        onClick={this.handTakeAway.bind(this)}
                                >
                                    Xử lý sau
                                </Button>
                                <Button className="mr-2 p-3 w-20 btn-danger" 
                                        onClick={this.toggle.bind(this,"show_reason",null,null)}
                                >
                                    Hủy yêu cầu
                                </Button>
                            </Row>
                            <center>
                            <Row className={utils.isEmpty(this.state.historyCustomer) ? "d-none":"d-block mt-4"}>
                                <ReactHTMLTableToExcel
                                    id="table-xls-button"
                                    className="download-table-xls-button float-right d-inline mx-2"
                                    table="table-customer-History"
                                    filename={`LỊCH SỬ TỪ ${utils.convertDate(this.state.fromDate)} ĐẾN ${utils.convertDate(this.state.toDate)} SĐT ${this.state.dataCustommer?this.state.dataCustommer.phoneNumber:"Trống"}`}
                                    sheet={`sheet1`}
                                    buttonText="Tải xuống"
                                />
                                <Input type="select" name="option_history" className="w-20 float-right mb-2" onChange={this.handChangeOptionHistory.bind(this)}>
                                    <option key="0" defaultValue>Tất cả hoạt động</option>
                                    <option key="1">Nạp tiền</option>
                                    <option key="2">Rút tiền</option>
                                    <option key="3">Mua vé</option>
                                </Input>
                                <Table bordered id="table-customer-History">
                                    <thead>
                                        <tr>
                                            <th className="content-title-bold">Hoạt động</th>
                                            <th className="content-title-bold">Số tiền</th>
                                            <th className="content-title-bold">Số tiền trước khi giao dịch</th>
                                            <th className="content-title-bold">Số tiền sau khi giao dịch</th>
                                            <th className="content-title-bold">Ngày nạp/rút tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.historyCustomer.map((row,key)=>{
                                                return(
                                                    <tr>
                                                        <td>{actionRechargeOnDay.find(action => action.id == row.fluctuationTypeId).label}</td>
                                                        <td>{(row.value*1000).getMoneyFormat() + " đ"}</td>
                                                        <td>{(row.beforeTransaction*1000).getMoneyFormat() + " đ"}</td>
                                                        <td>
                                                            {(row.fluctuationTypeId=== "1" || row.fluctuationTypeId === "2"|| row.fluctuationTypeId === "9"|| row.fluctuationTypeId === "10") 
                                                            ? (((row.beforeTransaction-row.value)*1000).getMoneyFormat() + " đ") 
                                                            : (((row.beforeTransaction+row.value)*1000).getMoneyFormat() + " đ") 
                                                            }
                                                        </td>
                                                        <td>{row.createdDate}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <Button className="float-left ml-2" disabled={this.state.historyPage===1} onClick={this.handPrePageHistory.bind(this)}>Trước</Button>
                                <Button className="float-right mr-2" onClick={this.handNextPageHistory.bind(this)}>Sau</Button>
                            </Row>
                            </center>
                        </Col>
                    </Row>
                    
                </Col>
            </React.Fragment>
        )
    }
}

export default Withdraw;
const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require("axios");

function litStaff(callback) {
    axios({
        url: config_api.admin,
        method: "GET",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: {},
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}

function createStaff(data, callback) {
    axios({
        url: config_api.admin + "/register",
        method: "POST",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: data,
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}

function editStaff(staffId, data, callback) {
    axios({
        url: config_api.admin + "/" + staffId,
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: data,
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}

function deleteStaff(staffId, callback) {
    axios({
        url: config_api.admin + "/" + staffId,
        method: "DELETE",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: {},
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}

function listStore(callback) {
    axios({
        url: config_api.store,
        method: "GET",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: {},
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}

function getConfig(callback) {
    axios({
        url: config_api.admin + "/config",
        method: "GET",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: {},
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}

function updateConfig(data, callback) {
    axios({
        url: config_api.admin + "/config",
        method: "POST",
        headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + utils.getAuthToken(),
        },
        data: data,
    })
        .then(result => {
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
}
module.exports = {
    litStaff: litStaff,
    createStaff: createStaff,
    editStaff: editStaff,
    deleteStaff: deleteStaff,
    listStore: listStore,
    getConfig: getConfig,
    updateConfig: updateConfig,
};

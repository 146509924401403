import React from "react";

import {
    Button,Input,FormFeedback,
    UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,
    Modal,ModalHeader,ModalBody,ModalFooter,
    InputGroup,InputGroupAddon,
} from "reactstrap";

import {  User,AlignJustify,TrendingUp} from "react-feather";

import utils from "../../utils";
import Notification from "../../components/Notification";
import ReCharge from "./rechange";
import Withdraw from "./withdraw";
const changePassword = require("../apiGeneral/changePassword");

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openHistory: false,
            openModalChangePassword: false,
            oldPassword : null,
            newPassword: null,
            rePassword: null,
        }
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    inputOnchange(event){
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangePassword(){
        if(utils.isEmpty(this.state.oldPassword) || utils.isEmpty(this.state.rePassword) || this.state.newPassword!== this.state.rePassword){
            Notification("error","Thiếu thông tin");
        }else{
            changePassword.changePassword(
                {currentPassword: this.state.oldPassword,password:this.state.newPassword},
                (err)=>{
                    if(err){
                        switch (err.data._error_message) {
                            case "Password doesn't match":
                                Notification("error","Không đúng mật khẩu hiện tại");
                                break;
                            case "Invalid password":
                                Notification("error","Mật khẩu không hợp lệ");
                                break;
                            case "Action failed":
                                Notification("error","Đổi mật khẩu thất bại");
                                break;
                            default:
                                Notification("error","Sai thông tin mật khẩu");
                                break;
                        }
                    } else {
                        Notification("success","Đổi mật khẩu thành công");
                        window.location.href = "/";
                    }
                }
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.openModalChangePassword}>
                    <ModalHeader>Đổi mật khẩu</ModalHeader>
                    <ModalBody>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Mật khẩu cũ</InputGroupAddon>
                            <Input type='password' name='oldPassword' invalid={utils.isEmpty(this.state.oldPassword) ? true : false} onChange={this.inputOnchange.bind(this)} />
                            <FormFeedback invalid>Mật khẩu cũ không được để trống</FormFeedback>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Mật khẩu mới</InputGroupAddon>
                            <Input type='password' name='newPassword' invalid={utils.isEmpty(this.state.newPassword) ? true : false} onChange={this.inputOnchange.bind(this)} />
                            <FormFeedback invalid>Mật khẩu mới không được để trống</FormFeedback>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Nhập lại mật khẩu</InputGroupAddon>
                            <Input
                                type='password'
                                name='rePassword'
                                invalid={this.state.newPassword !== this.state.rePassword ? true : false}
                                readOnly={this.state.newPassword ? false : true}
                                onChange={this.inputOnchange.bind(this)}
                            />
                            <FormFeedback invalid>Mật khẩu không khớp</FormFeedback>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={() => this.setState({ openModalChangePassword: false })}>
                            Hủy bỏ
                        </Button>
                        <Button color='success' onClick={this.handleChangePassword.bind(this)}>
                            Lưu
                        </Button>
                    </ModalFooter>
                </Modal>

                <div className="text-center pt-3 content-title-bold header-accountancy">
                    <button className={`float-left bg-white border-0 hidden-lg-up`} onClick={()=>this.props.toggle("showSidebar")} > <AlignJustify/> </button>
                    <UncontrolledDropdown className='float-right mr-1'>
                        <span className='d-none d-inline-block'>
                            <DropdownToggle nav caret className="pt-0">
                                <User />
                                <button className='text-dark bg-transparent border-0 hidden-sm-down'>{utils.getUserinfo().full_name} </button>
                            </DropdownToggle>
                        </span>
                        <DropdownMenu right>
                            <DropdownItem
                                onClick={()=>this.setState({ openModalChangePassword: true })}
                            >
                                Đổi mật khẩu
                            </DropdownItem>
                            <DropdownItem onClick={utils.logOut.bind()}>
                                Đăng xuất
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <button className="text-primary bg-white border-0 float-right mr-1 mt-1 " onClick={()=>{window.location.replace("/accountancy/statistical")}}>  <TrendingUp className="mr-2"/>Thống kê</button>
                </div>
                <hr/>
                <div className="p-2">
                        {
                            this.props.dataBanking !== null && this.props.dataBanking.action === "rút tiền"
                            ? <Withdraw
                                className={this.state.openHistory === true?"d-none":""}
                                dataBanking={this.props.dataBanking}
                                toggle = {this.props.toggle}
                                listReason = {this.props.listReason.withdraw}
                               />
                            :<ReCharge
                                className={this.state.openHistory === true?"d-none":""}
                                dataBanking={this.props.dataBanking}
                                toggle = {this.props.toggle}
                                listReason = {this.props.listReason.rechange}
                            />
                        }
                </div>
            </React.Fragment>
        )
    }
}

export default Content;
const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');

function listStore(callback) {
    let getListStore = localStorage.getItem("listStore");
    if( getListStore ){
        return callback(false, JSON.parse(getListStore))
    } else{
        axios({
            url: config_api.store,
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + utils.getAuthToken()
            },
            data: {}
        })
        .then(result => {
            result.data = result.data.sortBy("storeName");
            localStorage.setItem('listStore',JSON.stringify(result.data));
            return callback(false, result.data);
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
    }
}

function listStoreActive(callback) {
    let getListStore = localStorage.getItem("listStore");
    if( getListStore ){
        getListStore = JSON.parse(getListStore);
        getListStore = getListStore.filter(store => store.isActive === true);
        return callback(false, getListStore)
    } else{
        axios({
            url: config_api.store+"?isActive=true",
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + utils.getAuthToken()
            },
            data: {}
        })
        .then(result => {
            return callback(false, result.data)
        })
        .catch(error => {
            if (error.response) {
                return callback(error.response);
            } else if (error.request) {
                return callback("Đường truyền bị gián đoạn");
            } else {
                return callback(error.message);
            }
        });
    }
}

function createStore(data,callback) {
    axios({
        url: config_api.store,
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        let getListStore = localStorage.getItem("listStore");
        getListStore = JSON.parse(getListStore);
        getListStore.push(result.data);
        localStorage.setItem('listStore',JSON.stringify(getListStore));
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function editStore(storeId,data,callback) {
    axios({
        url: config_api.store+"/"+storeId,
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        localStorage.removeItem("listStore");
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function deleteStore(storeId,callback) {
    axios({
        url: config_api.store+"/"+storeId,
        method: 'DELETE',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        let getListStore = localStorage.getItem("listStore");
        getListStore = JSON.parse(getListStore);
        getListStore = getListStore.filter(store => store.id !== storeId);;
        localStorage.setItem('listStore',JSON.stringify(getListStore));
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports = {
    createStore:createStore,
    editStore: editStore,
    deleteStore: deleteStore,
    listStore:  listStore,
    listStoreActive: listStoreActive,
};
import React from "react";

import {
    Row,
    Button,FormGroup,
    Label,
} from "reactstrap";

import {  MinusCircle,PlusCircle} from "react-feather";

import utils from "../../utils";
import Notification from "../../components/Notification";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const apiHistory = require("../apiGeneral/apiHistory");
const {listStore} = require("../Admin/api/apiStore");
const get_urlImage = require("../../config/config").get_urlImage;
const actionHistoryId = require("../../config/config").actionHistoryId;


class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listStore: [],
            fromdate: null,
            todate: null,
        };
        this.rowPage = [50,100,200,250];
        this.filter={
            size: 100,
            page:1,
            staffid: utils.getUserinfo().id
        };
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    getApiHistory(page) {
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
        }

        listStore((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({}, this.state);
                result.map(value => {
                    state.listStore[value.id] = value.storeName;
                });

                apiHistory.getHistory(utils.convertDate(state.fromdate), utils.convertDate(state.todate), this.filter, (err, result) => {
                    if (err) {
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                    } else {
                        state.data = [];
                        result.map((value,key) => {
                            if(value.actionId === 35){
                                state.data.push({
                                    stt: (this.filter.page-1)*this.filter.size+key+1,
                                    id: value.id,
                                    createdDate: value.createdDate,
                                    fullName: value.staff.fullName,
                                    username: value.staff.username,
                                    store: utils.isEmpty(value.staff.storeId) ? "---" : state.listStore[value.staff.storeId.toString()],
                                    activity: actionHistoryId.find(action => action.id === value.actionId).label,
                                    userAffected: value.affectedObjectStatic.code||"---",
                                    amount: value.transactionStatic ? (value.transactionStatic.value).getMoneyFormat() + " đ" : "---",
                                    proof: utils.isEmpty(value.transactionStatic.image) ? null : get_urlImage(value.transactionStatic.image),
                                    reject: value.transactionStatic,
                                });
                            }
                            else if(utils.isEmpty(value.transactionStatic)){
                                state.data.push({
                                    stt: (this.filter.page-1)*this.filter.size+key+1,
                                    id: value.id,
                                    createdDate: value.createdDate,
                                    fullName: value.staff.fullName,
                                    username: value.staff.username,
                                    store: utils.isEmpty(value.staff.storeId) ? "---" : state.listStore[value.staff.storeId.toString()],
                                    activity: actionHistoryId.find(action => action.id === value.actionId).label,
                                    userAffected: value.affectedObjectStatic.phoneNumber,
                                    amount: value.transactionStatic ? (value.transactionStatic.value * 1000).getMoneyFormat() + " đ" : "---",
                                    proof: null,
                                    reject: {reason:"---"},
                                });
                            }else{
                                state.data.push({
                                    stt: (this.filter.page-1)*this.filter.size+key+1,
                                    id: value.id,
                                    createdDate: value.createdDate,
                                    fullName: value.staff.fullName,
                                    username: value.staff.username,
                                    store: utils.isEmpty(value.staff.storeId) ? "---" : state.listStore[value.staff.storeId.toString()],
                                    activity: actionHistoryId.find(action => action.id === value.actionId).label,
                                    userAffected: value.affectedObjectStatic.phoneNumber,
                                    amount: value.transactionStatic ? (value.transactionStatic.value * 1000).getMoneyFormat() + " đ" : "---",
                                    proof: utils.isEmpty(value.transactionStatic.image) ? null : get_urlImage(value.transactionStatic.image),
                                    reject: value.transactionStatic,
                                });
                            }
                        });
                        state.data = state.data.reverse();
                        this.setState(state);
                    }
                });
            }
        });
    }

    componentDidMount() {
        this.getApiHistory(1);
    }

    render() {
        const expandRow = {
            renderer: row => (
                <div className='pl-5'>
                    {utils.isEmpty(row.proof) ? (
                        <div>
                            {"Lý do hủy yêu cầu: "+row.reject.reason}
                        </div>
                    ) : (
                        <div>
                            {utils.isEmpty(row.reject.reason) ? null:"Ghi chú: "+row.reject.reason}
                            <img className='ticket-photo d-block' src={row.proof} />
                        </div>
                    )}
                </div>
            ),
            expandHeaderColumnRenderer: ({ isAnyExpands }) => (isAnyExpands ? <MinusCircle width={16} height={16} /> : <PlusCircle width={16} height={16} />),
            expandColumnRenderer: ({ expanded }) => (expanded ? <MinusCircle width={16} height={16} /> : <PlusCircle width={16} height={16} />),
        };
        const tableColumns = [
            {
                dataField: "stt",
                text: "STT",
                headerStyle: { width: '50px' }
            },
            {
                dataField: "store",
                text: "Cửa hàng"
            },
            {
                dataField: "activity",
                text: "Hoạt động"
            },
            {
                dataField: "userAffected",
                text: "SĐT khách hàng"
            },
            {
                dataField: "amount",
                text: "Số tiền",
                sort: true
            },
            {
                dataField: "createdDate",
                text: "Thời gian",
                sort: false
            },
        ];
        return (
            <React.Fragment>
                <div className={`${this.props.className} px-4`}>
                    <Row className='justify-content-center'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block ">Ngày bắt đầu</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromdate===null ? new Date(): this.state.fromdate}
                                onChange={date =>this.setState({fromdate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 ml-3'>
                            <Label className="d-block ">Ngày kết thúc</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.todate===null ? new Date(): this.state.todate}
                                onChange={date => this.setState({todate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2'>
                            <Button color='primary' onClick={this.getApiHistory.bind(this, 1)}>
                                Tìm kiếm
                            </Button>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="download-table-xls-button float-right d-inline mx-2"
                                table="table-accountantHistory"
                                filename={`LỊCH SỬ TỪ ${utils.convertDate(this.state.fromDate)} ĐẾN ${utils.convertDate(this.state.toDate)} USER ${utils.getUserinfo().full_name}`}
                                sheet={`sheet1`}
                                buttonText="Tải xuống"
                            />
                        </div>
                    </Row>
                    <div className="overflow-x-auto table-overflow-x">
                        <BootstrapTable
                            id = "table-accountantHistory"
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.data}
                            columns={tableColumns}
                            expandRow={expandRow}
                            filter={filterFactory()}
                        />
                    </div>
                    
                    <Row className="my-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            <div className="float-right px-2">
                                <Button disabled={this.filter.page===1} onClick={this.getApiHistory.bind(this,"-")}>{`<`}</Button>
                                <Button className="btn-danger">{this.filter.page}</Button>
                                <Button hidden={this.state.data.length < this.filter.size} onClick={this.getApiHistory.bind(this,"+")}>{this.filter.page+1}</Button>
                                <Button disabled={this.state.data.length < this.filter.size} onClick={this.getApiHistory.bind(this,"+")}>{`>`}</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default History;
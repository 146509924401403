const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');

function listCity(callback) {
    axios.get(config_api.administrative+"/city")
        .then(res => {
            let data = [];
            Object.keys(res.data).forEach(key => {
                data.push(res.data[key]);
            });
            return callback(null, data.sortBy("name"));
        })
        .catch(error => {
            return callback(error);
        })
}

function listDistrict(cityId,callback) {
    axios({
        url: config_api.administrative+"/city/"+cityId+"/district",
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        let data = [];
        Object.keys(result.data).forEach(key => {
            data.push(result.data[key]);
        });
        return callback(false, data.sortBy("name"));
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function getDistrict(districtId,callback) {
    axios({
        url: config_api.administrative+ "/district/"+ districtId,
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports = {
    listCity : listCity,
    listDistrict:listDistrict,
    getDistrict:getDistrict
};
const config_api = require("../../../config/config").config_api;
const axios = require('axios');

function login(email,password,callback) {
    axios({
        url: config_api.admin + "/auth",
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=utf-8",
        },
        data: {
            "username": email.toLowerCase(),
            "password": password
        },
        timeout: 30*1000,
    })
    .then(result => {
        return callback(false, result.data);
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response)
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message) 
        }
    });
}

module.exports = {
    login: login
};